import React, { useEffect, useState } from 'react'
import { Modal, DatePicker, Select, Button, Form, Input, message } from 'antd'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useCustomI18NTranslatorHook } from '../../../../../utility/globalization'
import { fetchHotelLocations } from '../../../../../services/location'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../../../../Store'
import moment from 'moment'
import SuccessModal from '../../../../Common/Modals/SuccessModal'
import { v4 as uuidv4 } from 'uuid'

const { Option } = Select

const ScheduleTaskModal = ({
  isVisible,
  onCancel,
  onSubmit,
  form,
  editingTask,
}) => {
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const dispatch = useDispatch()
  const { hotelInfo, locationIdToInfo } = useSelector(state => state)
  const housekeepingStaff = useSelector(state => state.housekeepingStaff)
  const staffList = useSelector(state => state.staffList)
  const [locations, setLocations] = useState([])
  const [departments, setDepartments] = useState([])
  const [services, setServices] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  useEffect(() => {
    if (staffList.length > 0 && housekeepingStaff.length === 0) {
      const filteredStaff = staffList
        .filter(staff => staff.department === 'House Keeping' && staff.id)
        .map(staff => ({ value: staff.id, label: staff.name }))

      dispatch(actions.setHousekeepingStaff(filteredStaff))
    }
  }, [staffList, housekeepingStaff, dispatch])

  useEffect(() => {
    if (hotelInfo.hotelId) {
      fetchHotelLocations({ dispatch, hotelId: hotelInfo.hotelId })
    }
  }, [dispatch, hotelInfo.hotelId])

  useEffect(() => {
    if (locationIdToInfo) {
      setLocations(
        Object.values(locationIdToInfo).map(location => ({
          ...location,
        }))
      )
    }
  }, [locationIdToInfo])

  useEffect(() => {
    if (!isVisible) {
      setSelectedDepartment(null)
      form.resetFields()
    }
  }, [isVisible, form])

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const db = firebase.firestore()
        const hotelTasksRef = db.collection('tasks').doc(hotelInfo.hotelId)
        const doc = await hotelTasksRef.get()

        if (doc.exists && doc.data().services) {
          const servicesData = doc.data().services
          const activeDepartments = servicesData
            .filter(s => s.status !== false)
            .map(s => s.department)
          const departmentsSet = new Set(activeDepartments)
          setDepartments(Array.from(departmentsSet))
        } else {
          setDepartments([])
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
      }
    }

    fetchDepartments()
  }, [hotelInfo.hotelId])

  useEffect(() => {
    if (editingTask) {
      setSelectedDepartment(editingTask.department)
      fetchServices(editingTask.department)

      form.setFieldsValue({
        ...editingTask,
        startDate: moment(editingTask.startDate, 'DD-MM-YYYY'),
        endDate: moment(editingTask.endDate, 'DD-MM-YYYY'),
        roomNumbers: editingTask.roomNumbers,
        staff: editingTask.staffId,
      })
    }
  }, [editingTask, form])

  const checkExistingTasks = async values => {
    const db = firebase.firestore()
    const hotelTasksRef = db
      .collection('tasks')
      .doc(hotelInfo.hotelId)
      .collection('hotelTasks')

    const startDate = moment(values.startDate)
    const endDate = moment(values.endDate)
    const daysDiff = endDate.diff(startDate, 'days') + 1

    for (let i = 0; i < daysDiff; i++) {
      const scheduleDate = moment(startDate).add(i, 'days').format('DD-MM-YYYY')

      for (const room of values.roomNumbers) {
        const existingTasksSnapshot = await hotelTasksRef
          .where('scheduleDate', '==', scheduleDate)
          .where('roomNumbers', 'array-contains', room)
          .where('service', '==', values.service)
          .where('department', '==', values.department)
          .get()

        if (!existingTasksSnapshot.empty) {
          return { exists: true, date: scheduleDate, room: room }
        }
      }
    }

    return { exists: false }
  }

  const handleSubmit = async values => {
    setSubmitLoading(true)
    try {
      if (!editingTask) {
        // Only check for existing tasks when creating a new task, not when updating
        const existingTask = await checkExistingTasks(values)
        if (existingTask.exists) {
          message.error(
            `A task already exists for room ${existingTask.room} on ${existingTask.date} with the same service and department.`
          )
          setSubmitLoading(false)
          return
        }
      }

      const selectedStaff = housekeepingStaff.find(
        staff => staff.value === values.staff
      )

      const startDate = moment(values.startDate)
      const endDate = moment(values.endDate)
      const baseTaskData = {
        department: values.department,
        staffId: values.staff,
        staffName: selectedStaff ? selectedStaff.label : '',
        service: values.service,
        startDate: startDate.format('DD-MM-YYYY'),
        endDate: endDate.format('DD-MM-YYYY'),
        lastServiceDate: firebase.firestore.Timestamp.now(),
        note: values.note || '',
      }

      const roomNumbers = values.roomNumbers

      const db = firebase.firestore()
      const hotelTasksRef = db.collection('tasks').doc(hotelInfo.hotelId)

      if (editingTask) {
        const existingTasksSnapshot = await hotelTasksRef
          .collection('hotelTasks')
          .where('staffName', '==', editingTask.assignedStaff)
          .where('department', '==', editingTask.department)
          .where('service', '==', editingTask.service)
          .get()

        const existingTasks = existingTasksSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))

        const batch = db.batch()

        existingTasks.forEach(task => {
          batch.delete(hotelTasksRef.collection('hotelTasks').doc(task.id))
        })

        const daysDiff = endDate.diff(startDate, 'days') + 1

        let noteComment = null
        if (values.note) {
          noteComment = {
            id: uuidv4(),
            staffId: values.staff,
            staffName: selectedStaff ? selectedStaff.label : '',
            date: firebase.firestore.Timestamp.now(),
            description: values.note,
            type: 'Note',
          }
        }

        const existingTaskData = new Map()
        existingTasks.forEach(task => {
          const key = `${task.scheduleDate}-${task.roomNumbers[0]}`
          existingTaskData.set(key, {
            status: task.status,
            startTime: task.startTime,
            completedTime: task.completedTime,
            completeddate: task.completeddate,
          })
        })

        for (let i = 0; i < daysDiff; i++) {
          const scheduleDate = moment(startDate)
            .add(i, 'days')
            .format('DD-MM-YYYY')

          roomNumbers.forEach(room => {
            const newTaskRef = hotelTasksRef.collection('hotelTasks').doc()
            const taskKey = `${scheduleDate}-${room}`
            const existingData = existingTaskData.get(taskKey) || {}

            const newTask = {
              ...baseTaskData,
              roomNumbers: [room],
              scheduleDate: scheduleDate,
              Task_id: newTaskRef.id,
              status: existingData.status || 'Pending',
              comments: noteComment ? [noteComment] : [],
              startTime: existingData.startTime || null,
              completedTime: existingData.completedTime || null,
              completeddate: existingData.completeddate || null,
            }
            batch.set(newTaskRef, newTask)
          })
        }

        await batch.commit()
        setSuccessMessage('Task updated successfully')
      } else {
        const daysDiff = endDate.diff(startDate, 'days') + 1
        const batch = db.batch()

        let noteComment = null
        if (values.note) {
          noteComment = {
            id: uuidv4(),
            staffId: values.staff,
            staffName: selectedStaff ? selectedStaff.label : '',
            date: firebase.firestore.Timestamp.now(),
            description: values.note,
            type: 'Note',
          }
        }

        for (let i = 0; i < daysDiff; i++) {
          const scheduleDate = moment(startDate)
            .add(i, 'days')
            .format('DD-MM-YYYY')

          for (const room of roomNumbers) {
            const taskRef = hotelTasksRef.collection('hotelTasks').doc()
            const task = {
              ...baseTaskData,
              roomNumbers: [room],
              scheduleDate: scheduleDate,
              Task_id: taskRef.id,
              status: 'Pending',
              comments: noteComment ? [noteComment] : [],
            }
            batch.set(taskRef, task)
          }
        }

        await batch.commit()
        setSuccessMessage('Task scheduled successfully')
      }
      setShowSuccessModal(true)
      onSubmit()
    } catch (error) {
      console.error('Error saving task:', error)
      message.error('Failed to save task')
    } finally {
      setSubmitLoading(false)
    }
  }

  const handleDepartmentChange = async value => {
    setSelectedDepartment(value)
    form.setFieldsValue({ service: undefined, staff: undefined })
    fetchServices(value)
  }

  const fetchServices = async department => {
    try {
      const db = firebase.firestore()
      const hotelTasksRef = db.collection('tasks').doc(hotelInfo.hotelId)
      const doc = await hotelTasksRef.get()

      if (doc.exists && doc.data().services) {
        const servicesData = doc.data().services
        const filteredServices = servicesData
          .filter(s => s.department === department && s.status !== false)
          .map(s => s.service)
        setServices(filteredServices)
      } else {
        setServices([])
      }
    } catch (error) {
      console.error('Error fetching services:', error)
    }
  }

  const handleCancel = () => {
    setSelectedDepartment(null)
    form.resetFields()
    onCancel()
  }

  const disabledDate = current => {
    return current && current < moment().startOf('day')
  }

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  return (
    <>
      <Modal
        title={translateTextI18N(
          editingTask ? 'Edit Recurring Task' : 'Schedule Recurring Task'
        )}
        visible={isVisible}
        onCancel={handleCancel}
        centered
        footer={null}
        className='addtitleModal cmnModal'
        maskClosable={false}
        width={600}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
          initialValues={{}}
        >
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>

          <div className='row' id='myprofile-form'>
            <div className='col-12 col-md-6'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={translateTextI18N('Schedule Start Date')}
                  name='startDate'
                  rules={[
                    {
                      required: true,
                      message: translateTextI18N('Please select a start date'),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format='DD MMM YYYY'
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </div>

            <div className='col-12 col-md-6'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={translateTextI18N('Schedule End Date')}
                  name='endDate'
                  rules={[
                    {
                      required: true,
                      message: translateTextI18N('Please select an end date'),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format='DD MMM YYYY'
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input group-input'>
                <Form.Item
                  label={translateTextI18N('Department')}
                  name='department'
                  rules={[
                    {
                      required: true,
                      message: translateTextI18N('Please select a department'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={translateTextI18N('Department')}
                    optionFilterProp='children'
                    className='editButonnSelect'
                    dropdownClassName='editButonn-dropdown'
                    onChange={handleDepartmentChange}
                    filterOption={filterOption}
                  >
                    {departments.map(dept => (
                      <Option key={dept} value={dept}>
                        {translateTextI18N(dept)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={translateTextI18N('Staff')}
                  name='staff'
                  rules={[
                    {
                      required: true,
                      message: translateTextI18N(
                        'Please select a staff member'
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={translateTextI18N('Staff')}
                    loading={housekeepingStaff.length === 0}
                    disabled={!selectedDepartment && !editingTask}
                    filterOption={filterOption}
                  >
                    {(selectedDepartment || editingTask) &&
                      housekeepingStaff.map((staff, index) => (
                        <Option
                          key={staff.value || `staff-${index}`}
                          value={staff.value}
                        >
                          {staff.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={translateTextI18N('Location')}
                  name='roomNumbers'
                  rules={[
                    {
                      required: true,
                      message: translateTextI18N('Please select room numbers'),
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={translateTextI18N('Room Number')}
                    filterOption={filterOption}
                  >
                    {locations
                      .sort((a, b) => {
                        const numA = parseInt(a.locationName, 10)
                        const numB = parseInt(b.locationName, 10)
                        return isNaN(numA) || isNaN(numB)
                          ? a.locationName.localeCompare(b.locationName)
                          : numA - numB
                      })
                      .map(location => (
                        <Option key={location.id} value={location.locationName}>
                          {location.locationName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label={translateTextI18N('Service')}
                  name='service'
                  rules={[
                    {
                      required: true,
                      message: translateTextI18N('Please select a service'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={translateTextI18N('Service')}
                    disabled={!selectedDepartment && !editingTask}
                    filterOption={filterOption}
                  >
                    {(selectedDepartment || editingTask) &&
                      services.map(service => (
                        <Option key={service} value={service}>
                          {translateTextI18N(service)}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className='col-12'>
              <div className='form-group cmn-input'>
                <Form.Item label={translateTextI18N('Note')} name='note'>
                  <Input.TextArea
                    placeholder={translateTextI18N(
                      'Enter any additional notes (optional)'
                    )}
                    rows={3}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              {translateTextI18N('Cancel')}
            </Button>
            <Button
              className='blueBtn ml-4'
              key='submit'
              htmlType='submit'
              type='primary'
              loading={submitLoading}
            >
              {translateTextI18N(editingTask ? 'Update' : 'Submit')}
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        visible={showSuccessModal}
        onCancel={() => setShowSuccessModal(false)}
        className='successModal'
        footer={null}
        centered
      >
        <SuccessModal title={successMessage}></SuccessModal>
      </Modal>
    </>
  )
}

export default ScheduleTaskModal
