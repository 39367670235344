import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Layout,
  Input,
  message as AntdMessage,
  Modal,
  Select,
  DatePicker,
  TimePicker,
  Form,
  Button,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import Header from '../../Common/Header/Header'
import PageNamecard from '../../Common/PageNameCard/PageNameCard'
import SideMenu from '../../Common/Sidemenu/Sidemenu'

import {
  GetPostfix,
  GetDateTimeString,
  SetAutoClearProp,
  GetEscalationTime,
  getServerDate,
} from '../../../config/utils'
import { useCustomI18NTranslatorHook } from '../../../utility/globalization'
import { saveServiceRequest } from '../../../services/requests'
import { creationData } from '../../../services/common'
import {
  departmentWithOutServiceObj,
  pendingLable,
  defaultEscalationTime,
} from '../../../config/constants'
import DepartmentAndServiceKeys from '../../../config/departmentAndServicekeys'
import { serviceType } from '../../../config/departmentAndServicekeys'
import { fetchHotelLocations } from '../../../services/location'
import { AddRoomTypeListener } from '../../../services/roomType'

const { Content } = Layout

const styles = {
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 350px)',
    marginBottom: '10px',
  },
  chatHistory: {
    flex: 1,
    overflowY: 'auto',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  messageGroup: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  userMessage: {
    backgroundColor: '#4996d9',
    color: '#fff',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
    maxWidth: '100%',
    wordBreak: 'break-word',
  },
  assistantMessage: {
    backgroundColor: '#f0f0f0',
    padding: '0.5rem 1rem 0.5rem 3rem',
    borderRadius: '0.5rem',
    position: 'relative',
    maxWidth: '100%',
    wordBreak: 'break-word',
  },
  logo: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    left: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  inputContainer: {
    padding: '1rem 1rem 2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
  },
  inputSearch: {
    width: '100%',
    borderRadius: '0.5rem',
    padding: '0.5rem 1rem',
    border: '1px solid #d9d9d9',
    marginRight: '1rem',
  },
  sendButton: {
    borderRadius: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#2688D0',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
}

const { TextArea } = Input
const { Option } = Select

const data = [
  {
    service: 'book taxi',
    reply: 'book taxi for room 102 on 5th september 9am',
  },
  {
    service: 'clean room',
    reply: 'clean room 102 at 12pm',
  },
  {
    service: 'get my car',
    reply: 'get car for room 102 on 5th september 9am, ticket no: XXXXX',
  },
  {
    service: 'ugrade room',
    reply: 'upgrade room 102 to room type: single room',
  },
]

const randomIndex = Math.floor(Math.random() * data.length)
const { service, reply } = data[randomIndex]

const ChatWithIva = () => {
  // State and hooks
  const [messages, setMessages] = useState([
    {
      userText: 'Hello',
      assistantText: 'Hi there! How can I assist you today?',
    },
    {
      userText: `*TIP: you can send direct request for ${service}`,
      assistantText: `${reply}`,
    },
  ])
  const [form] = Form.useForm()
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState('')
  const [error, setError] = useState('')
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const [serverDate, setServerDate] = useState(null)
  const [roomNumbers, setRoomNumbers] = useState([])
  const [roomsData, setRoomsData] = useState([])
  const [pendingRequest, setPendingRequest] = useState(null)
  const [comment, setComment] = useState('')
  const [roomNumber, setRoomNumber] = useState('')

  //new states
  const [popupState, setPopupState] = useState({
    showRoomNumber: false,
    showRoomType: false,
    showTicketNumber: false,
    showDaysToExtend: false,
    showNumberOfBeds: false,
    showDateTime: false,
    showConfirmation: false,
    showTimeOnly: false,
  })
  const [requestStatus, setRequestStatus] = useState('')

  const [roomType, setRoomType] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')
  const [daysToExtend, setDaysToExtend] = useState('')
  const [numberOfBeds, setNumberOfBeds] = useState('')

  const [invalidDate, setInvalidDate] = useState(false)
  const [invalidRoomNumber, setInvalidRoomNumber] = useState(false)
  const [invalidRoomType, setInvalidRoomType] = useState(false)
  const [pastDate, setPastDate] = useState(false)
  const [pastTime, setPastTime] = useState(false)
  const [selectedDateBtn, setSelectedDateBtn] = useState(true)
  const [selectedTimeBtn, setSelectedTimeBtn] = useState(true)

  const resetForm = () => {
    form.resetFields()
  }

  const dispatch = useDispatch()

  const chatHistoryRef = useRef(null)

  const {
    hotelInfo,
    userInfo,
    currentLanguage,
    roomTypes,
    locationIdToInfo,
    departmentsNew,
    servicesNew,
  } = useSelector(state => state)
  const { hotelId } = hotelInfo

  const [retryCount, setRetryCount] = useState(0)
  const MAX_RETRIES = 1

  // FETCH LOCATIONS
  useEffect(() => {
    const fetchLocations = () => {
      const data = locationIdToInfo
      const roomNums = Array.from(
        new Set(
          Object.values(data)
            .map(item => item.locationName)
            .filter(name => !isNaN(name))
        )
      )

      // console.log('Available locations: ', roomNums);
      setRoomNumbers(roomNums)

      // If data is empty and we haven't reached max retries, schedule another attempt
      if (roomNums.length === 0 && retryCount < MAX_RETRIES) {
        setRetryCount(prevCount => prevCount + 1)
      }
    }

    fetchLocations()
  }, [locationIdToInfo, retryCount])

  // ROOM TYPES FETCH
  useEffect(() => {
    const fetchRoomTypes = () => {
      const data = roomTypes
      const roomDetails = data.map(item => ({
        id: item.id,
        roomName: item.roomName,
      }))

      // console.log('Room Types available: ', roomDetails)
      setRoomsData(roomDetails)

      // If data is empty and we haven't reached max retries, schedule another attempt
      if (roomDetails.length === 0 && retryCount < MAX_RETRIES) {
        setRetryCount(prevCount => prevCount + 1)
      }
    }

    fetchRoomTypes()
  }, [roomTypes, retryCount])

  // You can add a useEffect to log when retries are exhausted
  useEffect(() => {
    if (retryCount >= MAX_RETRIES) {
      // console.log('Max retries reached. Unable to fetch data.')
    }
  }, [retryCount])

  useEffect(() => {
    const chatHistoryElement = chatHistoryRef.current
    if (chatHistoryElement) {
      chatHistoryElement.scrollTop = chatHistoryElement.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    getInitialDateTime()
  }, [])

  useEffect(() => {
    fetchHotelLocations({ dispatch, hotelId })
    AddRoomTypeListener(hotelId, false, dispatch)
  }, [dispatch, hotelId])

  useEffect(() => {
    if (!popupState.showDateTime) {
      setInvalidDate(false)
    }
  }, [popupState.showDateTime])

  useEffect(() => {
    if (!popupState.showRoomNumber) {
      setInvalidRoomNumber(false)
    }
  }, [popupState.showRoomNumber])

  useEffect(() => {
    if (!popupState.showRoomType) {
      setInvalidRoomType(false)
    }
  }, [popupState.showRoomType])

  // Helper functions for text parsing
  const getRoomNumber = text => {
    const pattern = /room_number:\s*(\d+)/
    const match = text.match(pattern)
    return match ? match[1] : null
  }

  function getIdByKey(key) {
    const item = departmentsNew.find(obj => obj.key === key)
    return item ? item.id : null
  }

  const getDepartment = text => {
    const pattern = /department:\s*([^,]+)/
    const match = text.match(pattern)
    if (match) {
      const department = match[1].trim().toLowerCase()
      switch (department) {
        case 'front-desk':
          return {
            department_type: 'Front Desk',
            department: 'Front Desk',
            departmentId: getIdByKey('front-desk'),
            departmentKey: 'front-desk',
          }
        case 'concierge':
          return {
            department_type: 'Concierge',
            department: 'Concierge',
            departmentId: getIdByKey('concierge'),
            departmentKey: 'concierge',
          }
        case 'house-keeping':
          return {
            department_type: 'Housekeeping',
            department: 'Housekeeping',
            departmentId: getIdByKey('house-keeping'),
            departmentKey: 'house-keeping',
          }
        default:
          return {
            department_type: '',
            department: '',
            departmentId: '',
            departmentKey: '',
          }
      }
    }
    return null
  }

  const createAiCodeToServiceIdMap = () => {
    const allServices = Object.values(servicesNew).flat()
    const aiCodeMap = {
      cr1: 'change-upgrade-room',
      ru1: 'change-upgrade-room',
      eb1: 'extra-bed',
      ad1: 'airport-dropoff',
      cr2: 'checkout-and-request-bill',
      es1: 'extend-stay',
      wc1: 'wake-up-call',
      dc1: 'doctor-on-a-call',
      td1: 'travel-desk',
      bt1: 'book-taxi',
      cr3: 'car-rental',
      gc1: 'get-my-car',
      tr1: 'toiletries',
      pr1: 'pillow',
      mr1: 'minibar',
      mr2: 'minibar',
      lr1: 'linen-bed',
      ct1: 'clean-tray',
      lp1: 'pick-laundry',
      rc1: 'room-cleaning',
    }

    const aiCodeToServiceIdMap = {}

    for (const [aiCode, serviceKey] of Object.entries(aiCodeMap)) {
      const service = allServices.find(s => s.key === serviceKey)
      if (service) {
        aiCodeToServiceIdMap[aiCode] = service.id
      }
    }

    return aiCodeToServiceIdMap
  }

  const aiCodeToServiceIdMap = createAiCodeToServiceIdMap()

  const getServiceFromAicode = text => {
    const pattern = /ai-code:\s*(\w+)/
    const match = text.match(pattern)
    if (match) {
      const aiCode = match[1]
      switch (aiCode) {
        case 'cr1':
          return {
            service: 'Change Room',
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.changeUpgradeRoom.key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.RoomUpgrade,
            aiCode: 'cr1',
          }
        case 'ru1':
          return {
            service: 'Upgrade Room',
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.changeUpgradeRoom.key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.RoomUpgrade,
            aiCode: 'ru1',
          }
        case 'eb1':
          return {
            service: DepartmentAndServiceKeys.frontDesk.services.extraBed.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.extraBed.key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.OtherRequest,
            aiCode: 'eb1',
          }
        case 'ad1':
          return {
            service:
              DepartmentAndServiceKeys.frontDesk.services.airportDropoff.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.airportDropoff.key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.ScheduledTime,
            aiCode: 'ad1',
          }
        case 'cr2':
          return {
            service:
              DepartmentAndServiceKeys.frontDesk.services.checkoutAndRequestBill
                .name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.checkoutAndRequestBill
                .key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.ScheduledTime,
            aiCode: 'cr2',
          }
        case 'es1':
          return {
            service:
              DepartmentAndServiceKeys.frontDesk.services.extendStay.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.extendStay.key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.OtherRequest,
            aiCode: 'es1',
          }
        case 'wc1':
          return {
            service:
              DepartmentAndServiceKeys.frontDesk.services.wakeUpCall.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.frontDesk.services.wakeUpCall.key,
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.ScheduledTime,
            aiCode: 'wc1',
          }
        case 'dc1':
          return {
            service:
              DepartmentAndServiceKeys.frontDesk.services.doctorOnACall.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey: '',
            frontDeskServiceType:
              DepartmentAndServiceKeys.frontDesk.type.OtherRequest,
            aiCode: 'dc1',
          }
        case 'td1':
          return {
            service:
              DepartmentAndServiceKeys.concierge.services.travelDesk.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.concierge.services.travelDesk.key,
            frontDeskServiceType: '',
            aiCode: 'td1',
          }
        case 'bt1':
          return {
            service: DepartmentAndServiceKeys.concierge.services.bookTaxi.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.concierge.services.bookTaxi.key,
            frontDeskServiceType: '',
            aiCode: 'bt1',
          }
        case 'cr3':
          return {
            service: DepartmentAndServiceKeys.concierge.services.carRental.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.concierge.services.carRental.key,
            frontDeskServiceType: '',
            aiCode: 'cr3',
          }
        case 'gc1':
          return {
            service: DepartmentAndServiceKeys.concierge.services.getMyCar.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.concierge.services.getMyCar.key,
            frontDeskServiceType: '',
            aiCode: 'gc1',
          }
        case 'tr1':
          return {
            service: 'Replacement',
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.toiletries.key,
            serviceType:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.toiletries.name,
            frontDeskServiceType: '',
            aiCode: 'tr1',
          }
        case 'pr1':
          return {
            service: 'Replacement',
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.pillow.key,
            serviceType:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.pillow.name,
            frontDeskServiceType: '',
            aiCode: 'pr1',
          }
        case 'mr1':
        case 'mr2':
          return {
            service: 'Replacement',
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.minibar.key,
            serviceType:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.minibar.name,
            frontDeskServiceType: '',
            aiCode: 'mr1',
          }
        case 'lr1':
          return {
            service: 'Replacement',
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.linenBed.key,
            serviceType:
              DepartmentAndServiceKeys.houseKeeping.services.replacement
                .services.linenBed.name,
            frontDeskServiceType: '',
            aiCode: 'lr1',
          }
        case 'ct1':
          return {
            service:
              DepartmentAndServiceKeys.houseKeeping.services.cleanTray.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.cleanTray.key,
            frontDeskServiceType: '',
            aiCode: 'ct1',
          }
        case 'lp1':
          return {
            service:
              DepartmentAndServiceKeys.houseKeeping.services.pickLaundry.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.pickLaundry.key,
            frontDeskServiceType: '',
            aiCode: 'lp1',
          }
        case 'rc1':
          return {
            service:
              DepartmentAndServiceKeys.houseKeeping.services.roomCleaning.name,
            serviceId: aiCodeToServiceIdMap[aiCode] || '',
            serviceKey:
              DepartmentAndServiceKeys.houseKeeping.services.roomCleaning.key,
            frontDeskServiceType: '',
            aiCode: 'rc1',
          }
        default:
          return null
      }
    }
    return null
  }

  // Date and time parsing functions
  const getDateMonthAndTime = str => {
    // Match pattern for day, month, and time (including variations)
    const fullMatch = str.match(
      /(?:day:\s*(\d{1,2})(?:st|nd|rd|th)?\s+(\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\b)|(\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\b)\s+(\d{1,2})(?:st|nd|rd|th)?)(?:,?\s*time:\s*(\d{1,2})\s*(am|pm))?/i
    )
    if (fullMatch) {
      const day = fullMatch[1] || fullMatch[4]
      const month = fullMatch[2] || fullMatch[3]
      const time =
        fullMatch[5] && fullMatch[6]
          ? `${fullMatch[5]}${fullMatch[6].toLowerCase()}`
          : null
      return {
        day: day ? parseInt(day) : null,
        month: month ? month.toLowerCase() : null,
        time: time,
      }
    }
    // Match pattern for just time
    const timeMatch = str.match(/time:\s*(\d{1,2})\s*(am|pm)/i)
    if (timeMatch) {
      return {
        day: null,
        month: null,
        time: `${timeMatch[1]}${timeMatch[2].toLowerCase()}`,
      }
    }
    // If no matches, return null for all fields
    return { day: null, month: null, time: null }
  }

  const getMonthNumber = monthName => {
    if (!monthName) return null
    const months = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ]
    const index = months.indexOf(monthName.toLowerCase())
    return index !== -1 ? (index + 1).toString().padStart(2, '0') : null
  }

  const convertToISODate = (day, month, time) => {
    if (!time) return null
    const now = new Date()
    const year = now.getFullYear()
    let monthNumber, dayNumber

    if (!day && !month) {
      monthNumber = (now.getMonth() + 1).toString().padStart(2, '0')
      dayNumber = now.getDate().toString().padStart(2, '0')
    } else {
      monthNumber = getMonthNumber(month)
      dayNumber = day ? day.toString().padStart(2, '0') : null
      if (!monthNumber || !dayNumber) return null
    }

    const [hours, period] = time.match(/(\d+)(am|pm)/i).slice(1)
    let formattedHours = parseInt(hours)
    if (period.toLowerCase() === 'pm' && formattedHours !== 12) {
      formattedHours += 12
    } else if (period.toLowerCase() === 'am' && formattedHours === 12) {
      formattedHours = 0
    }
    formattedHours = formattedHours.toString().padStart(2, '0')

    return `${year}-${monthNumber}-${dayNumber}T${formattedHours}:00:00`
  }

  // Extraction functions
  const extractRoomType = text => {
    const pattern = /room_type:\s*([^,]+)/
    const match = text.match(pattern)
    var roomType = match ? match[1].trim().toLowerCase() : null

    if (roomType) {
      if (roomType.includes('?')) {
        roomType = 'null'
      }
      const room = roomsData.find(
        room => room.roomName.toLowerCase() === roomType
      )

      if (!room && roomType !== 'null') {
        setInvalidRoomType(true)
      }
      return room ? room.id : null
    }
    return null
  }

  const extractDay = text => {
    const pattern = /day:\s*(\d+)/
    const match = text.match(pattern)
    return match ? `${match[1]} days` : null
  }

  const extractBeds = text => {
    const pattern = /beds:\s*(\d+)/
    const match = text.match(pattern)
    return match ? `${match[1]} beds` : null
  }

  const extractTicketNumber = response => {
    const match = response.match(/ticket_number:\s*(\d+)/)
    return match ? match[1] : null
  }

  // Modified handleUserInput function
  const handleUserInput = async () => {
    if (inputValue.trim()) {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          userText: inputValue,
          assistantText: 'Processing...',
        },
      ])

      const data = {
        user_query: inputValue,
        history: '',
        pipeline: 'default_pipeline',
      }

      const config = {
        method: 'post',
        url: 'https://iva.inplass.com/chat',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }

      try {
        const response = await axios(config)
        const result = response.data
        const final = result.response
        // console.log('Ai Response: ', final)

        let room_no = getRoomNumber(final)
        const dep = getDepartment(final)
        const serviceFromAi = getServiceFromAicode(final)
        let room_type = extractRoomType(final)
        let days = extractDay(final)
        let bed = extractBeds(final)
        let ticket_number = extractTicketNumber(final)

        let formattedDate = {}
        const { day, month, time } = getDateMonthAndTime(final)
        // console.log(day, month, time)

        if (time) {
          formattedDate = convertToISODate(day, month, time)

          const isPastDate = moment(formattedDate).isBefore(moment())

          const currentTime = moment()
          const givenTime = moment(time, 'HH:mm')

          givenTime.year(currentTime.year())
          givenTime.month(currentTime.month())
          givenTime.date(currentTime.date())

          const isPastTime = givenTime.isBefore(currentTime)

          if (isPastDate) {
            formattedDate = {}
            setPastDate(isPastDate)
          }

          if (isPastTime && day === null && month === null) {
            formattedDate = {}
            setPastTime(isPastTime)
          }
        }

        if (
          !(month === null && day === null && time === null) &&
          !(month !== null && day !== null && time !== null) &&
          ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
            serviceFromAi.aiCode
          )
        ) {
          formattedDate = {}
          setInvalidDate(true)
        }

        if (serviceFromAi === null) {
          // Show pop-up for unsupported service
          setMessages(prevMessages => {
            const newMessages = [...prevMessages]
            newMessages[newMessages.length - 1].assistantText =
              "Couldn't create Request. Unsupported service."
            return newMessages
          })

          Modal.info({
            title: 'Unsupported Service',
            content:
              'Currently this service is not supported in IVA requests. You can manually create it in Departments requests.',
          })

          setRequestStatus('unsupported')
          return
        } else {
          let newPopupState = {
            showRoomNumber: false,
            showRoomType: false,
            showTicketNumber: false,
            showDaysToExtend: false,
            showNumberOfBeds: false,
            showDateTime: false,
            showConfirmation: false,
          }

          if (room_no === null || !roomNumbers.includes(room_no)) {
            if (room_no !== null && !roomNumbers.includes(room_no)) {
              setInvalidRoomNumber(true)
            }
            newPopupState.showRoomNumber = true
            room_no = null // Reset room_no if it's not valid
          } else if (room_type === null && serviceFromAi.aiCode === 'ru1') {
            newPopupState.showRoomType = true
          } else if (ticket_number === null && serviceFromAi.aiCode === 'gc1') {
            newPopupState.showTicketNumber = true
          } else if (days === null && serviceFromAi.aiCode === 'es1') {
            newPopupState.showDaysToExtend = true
          } else if (bed === null && serviceFromAi.aiCode === 'eb1') {
            newPopupState.showNumberOfBeds = true
          } else if (
            ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
              serviceFromAi.aiCode
            ) &&
            JSON.stringify(formattedDate) === JSON.stringify({})
          ) {
            newPopupState.showDateTime = true
          } else if (
            ['ct1', 'lp1', 'rc1'].includes(serviceFromAi.aiCode) &&
            JSON.stringify(formattedDate) === JSON.stringify({})
          ) {
            newPopupState.showTimeOnly = true
          } else {
            newPopupState.showConfirmation = true
          }

          setPopupState(newPopupState)

          setPendingRequest({
            room_no: room_no, // Keep the original room_no, even if invalid
            dep,
            serviceFromAi,
            formattedDate,
            days,
            bed,
            room_type,
            ticket_number,
          })
          setComment('')

          setRequestStatus('pending')
        }
        // Update message based on request status
        setMessages(prevMessages => {
          const newMessages = [...prevMessages]
          switch (requestStatus) {
            case 'unsupported':
              newMessages[newMessages.length - 1].assistantText =
                "Couldn't create Request. Unsupported service."
              break
            case 'pending':
              newMessages[newMessages.length - 1].assistantText =
                'Request is pending confirmation'
              break
            default:
              newMessages[newMessages.length - 1].assistantText =
                'Processing complete'
          }
          return newMessages
        })
      } catch (error) {
        console.error('Error:', error)
        setMessages(prevMessages => {
          const newMessages = [...prevMessages]
          newMessages[newMessages.length - 1].assistantText =
            "Sorry, I couldn't process your request. Please try again."
          return newMessages
        })
      }

      setInputValue('')
    }
  }

  const handleDateTimeConfirm = selectedDateTime => {
    if (pendingRequest) {
      const formattedDateTime = moment(
        selectedDateTime,
        'YYYY-MM-DD HH:mm'
      ).format('YYYY-MM-DDTHH:mm:00')
      setPendingRequest(prev => ({ ...prev, formattedDate: formattedDateTime }))
      setPopupState(prev => ({
        ...prev,
        showDateTime: false,
        showConfirmation: true,
      }))
    }
  }

  const handleTimeConfirm = selectedTime => {
    if (pendingRequest) {
      const currentDate = moment().format('YYYY-MM-DD')
      const formattedDateTime = `${currentDate}T${selectedTime}:00`
      setPendingRequest(prev => ({ ...prev, formattedDate: formattedDateTime }))
      setPopupState(prev => ({
        ...prev,
        showTimeOnly: false,
        showConfirmation: true,
      }))
    }
  }

  const handleDaysToExtendConfirm = () => {
    if (pendingRequest) {
      const updatedRequest = { ...pendingRequest, days: `${daysToExtend} days` }
      setPendingRequest(updatedRequest)

      let nextPopupState = {
        showRoomNumber: false,
        showRoomType: false,
        showTicketNumber: false,
        showDaysToExtend: false,
        showNumberOfBeds: false,
        showDateTime: false,
        showConfirmation: false,
      }

      if (
        updatedRequest.bed === null &&
        updatedRequest.serviceFromAi.aiCode === 'eb1'
      ) {
        nextPopupState.showNumberOfBeds = true
      } else if (
        ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
          updatedRequest.serviceFromAi.aiCode
        ) &&
        JSON.stringify(updatedRequest.formattedDate) === JSON.stringify({})
      ) {
        nextPopupState.showDateTime = true
      } else {
        nextPopupState.showConfirmation = true
      }

      setPopupState(nextPopupState)
    }
    setDaysToExtend('')
  }

  const handleNumberOfBedsConfirm = () => {
    if (pendingRequest) {
      const updatedRequest = { ...pendingRequest, bed: `${numberOfBeds} beds` }
      setPendingRequest(updatedRequest)

      let nextPopupState = {
        showRoomNumber: false,
        showRoomType: false,
        showTicketNumber: false,
        showDaysToExtend: false,
        showNumberOfBeds: false,
        showDateTime: false,
        showConfirmation: false,
      }

      if (
        ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
          updatedRequest.serviceFromAi.aiCode
        ) &&
        JSON.stringify(updatedRequest.formattedDate) === JSON.stringify({})
      ) {
        nextPopupState.showDateTime = true
      } else {
        nextPopupState.showConfirmation = true
      }

      setPopupState(nextPopupState)
    }
    setNumberOfBeds('')
  }

  const handleTicketNumberConfirm = () => {
    if (pendingRequest) {
      const updatedRequest = { ...pendingRequest, ticket_number: ticketNumber }
      setPendingRequest(updatedRequest)

      let nextPopupState = {
        showRoomNumber: false,
        showRoomType: false,
        showTicketNumber: false,
        showDaysToExtend: false,
        showNumberOfBeds: false,
        showDateTime: false,
        showConfirmation: false,
      }

      if (
        updatedRequest.days === null &&
        updatedRequest.serviceFromAi.aiCode === 'es1'
      ) {
        nextPopupState.showDaysToExtend = true
      } else if (
        updatedRequest.bed === null &&
        updatedRequest.serviceFromAi.aiCode === 'eb1'
      ) {
        nextPopupState.showNumberOfBeds = true
      } else if (
        ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
          updatedRequest.serviceFromAi.aiCode
        ) &&
        JSON.stringify(updatedRequest.formattedDate) === JSON.stringify({})
      ) {
        nextPopupState.showDateTime = true
      } else {
        nextPopupState.showConfirmation = true
      }

      setPopupState(nextPopupState)
    }
    setTicketNumber('')
  }

  const handleRoomNumberConfirm = () => {
    if (pendingRequest) {
      const updatedRequest = { ...pendingRequest, room_no: roomNumber }
      setPendingRequest(updatedRequest)

      let nextPopupState = {
        showRoomNumber: false,
        showRoomType: false,
        showTicketNumber: false,
        showDaysToExtend: false,
        showNumberOfBeds: false,
        showDateTime: false,
        showConfirmation: false,
      }

      if (
        updatedRequest.room_type === null &&
        updatedRequest.serviceFromAi.aiCode === 'ru1'
      ) {
        nextPopupState.showRoomType = true
      } else if (
        updatedRequest.ticket_number === null &&
        updatedRequest.serviceFromAi.aiCode === 'gc1'
      ) {
        nextPopupState.showTicketNumber = true
      } else if (
        updatedRequest.days === null &&
        updatedRequest.serviceFromAi.aiCode === 'es1'
      ) {
        nextPopupState.showDaysToExtend = true
      } else if (
        updatedRequest.bed === null &&
        updatedRequest.serviceFromAi.aiCode === 'eb1'
      ) {
        nextPopupState.showNumberOfBeds = true
      } else if (
        ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
          updatedRequest.serviceFromAi.aiCode
        ) &&
        JSON.stringify(updatedRequest.formattedDate) === JSON.stringify({})
      ) {
        nextPopupState.showDateTime = true
      } else if (
        ['ct1', 'lp1', 'rc1'].includes(updatedRequest.serviceFromAi.aiCode) &&
        JSON.stringify(updatedRequest.formattedDate) === JSON.stringify({})
      ) {
        nextPopupState.showTimeOnly = true
      } else {
        nextPopupState.showConfirmation = true
      }

      setPopupState(nextPopupState)
    }
  }

  const handleRoomTypeConfirm = () => {
    if (pendingRequest) {
      const updatedRequest = { ...pendingRequest, room_type: roomType }
      setPendingRequest(updatedRequest)

      let nextPopupState = {
        showRoomNumber: false,
        showRoomType: false,
        showTicketNumber: false,
        showDaysToExtend: false,
        showNumberOfBeds: false,
        showDateTime: false,
        showConfirmation: false,
      }

      if (
        updatedRequest.ticket_number === null &&
        updatedRequest.serviceFromAi.aiCode === 'gc1'
      ) {
        nextPopupState.showTicketNumber = true
      } else if (
        updatedRequest.days === null &&
        updatedRequest.serviceFromAi.aiCode === 'es1'
      ) {
        nextPopupState.showDaysToExtend = true
      } else if (
        updatedRequest.bed === null &&
        updatedRequest.serviceFromAi.aiCode === 'eb1'
      ) {
        nextPopupState.showNumberOfBeds = true
      } else if (
        ['ad1', 'cr2', 'wc1', 'bt1', 'cr3', 'gc1'].includes(
          updatedRequest.serviceFromAi.aiCode
        ) &&
        JSON.stringify(updatedRequest.formattedDate) === JSON.stringify({})
      ) {
        nextPopupState.showDateTime = true
      } else {
        nextPopupState.showConfirmation = true
      }

      setPopupState(nextPopupState)
    }
  }

  const handleConfirm = () => {
    setPopupState({
      showRoomNumber: false,
      showRoomType: false,
      showTicketNumber: false,
      showConfirmation: false,
    })
    if (pendingRequest) {
      sendManualRequest(
        pendingRequest.room_no,
        pendingRequest.dep,
        pendingRequest.serviceFromAi,
        pendingRequest.formattedDate,
        pendingRequest.days,
        pendingRequest.bed,
        pendingRequest.room_type,
        pendingRequest.ticket_number,
        comment
      )
    }
    // Update request status
    setRequestStatus('sent')
    // Update message
    setMessages(prevMessages => {
      const newMessages = [...prevMessages]
      newMessages[newMessages.length - 1].assistantText =
        'Request sent successfully'
      return newMessages
    })
    setPendingRequest(null)
    setComment('')
    setInvalidDate(false)
    setPastTime(false)
  }

  const handleCancel = () => {
    setPopupState({
      showRoomNumber: false,
      showRoomType: false,
      showTicketNumber: false,
      showDaysToExtend: false,
      showNumberOfBeds: false,
      showDateTime: false,
      showTimeOnly: false,
      showConfirmation: false,
    })
    setPendingRequest(null)
    setComment('')
    setRoomNumber('')
    setRoomType('')
    setTicketNumber('')
    // Update request status
    setRequestStatus('cancelled')
    // Update message
    setMessages(prevMessages => {
      const newMessages = [...prevMessages]
      newMessages[newMessages.length - 1].assistantText = 'Request cancelled.'
      return newMessages
    })
    setInvalidDate(false)
    setPastTime(false)
  }

  // Chat history component
  const ChatHistory = ({ messages }) => {
    const scrollToBottom = () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight
      }
    }

    useLayoutEffect(() => {
      scrollToBottom()
    }, [messages])

    useEffect(() => {
      scrollToBottom()
    }, [messages])

    return (
      <div style={styles.chatHistory} ref={chatHistoryRef}>
        {messages.map((message, index) => (
          <div key={index} style={styles.messageGroup}>
            <div style={styles.userMessage}>{message.userText}</div>
            <div style={styles.assistantMessage}>
              <img src='images/ivawhite.png' alt='Logo' style={styles.logo} />
              {message.assistantText}
            </div>
          </div>
        ))}
      </div>
    )
  }

  // Get initial date and time
  async function getInitialDateTime() {
    try {
      const currentDate = await getServerDate()
      setServerDate(currentDate)

      // Set up interval after successfully getting the initial date
      setInterval(async () => {
        const updatedDate = await getServerDate()
        setServerDate(updatedDate)
      }, 1000 * 60)
      return currentDate
    } catch (error) {
      console.error('Error fetching server date:', error)
      return new Date() // Fallback to client-side date if server date fetch fails
    }
  }

  // Helper functions for request data
  function getGuestInfo({
    createdByName,
    fromDepartmentName,
    guestInfo,
    isGuestRequest,
  }) {
    if (!isGuestRequest) {
      return [
        {
          bookingReferance: '',
          guest: createdByName,
          guestId: '',
          roomNumber: fromDepartmentName,
        },
      ]
    }

    const guestList = Array.isArray(guestInfo) ? guestInfo : []
    const guestData = guestList.map(g => {
      let { id, name, surName, roomNumber, bookingReferance } = g

      if (!name) name = ''
      if (!surName) surName = ''
      if (!roomNumber) roomNumber = ''
      if (!bookingReferance) bookingReferance = ''

      return {
        bookingReferance,
        guest: `${name} ${surName}`,
        guestId: id,
        roomNumber,
      }
    })

    return guestData
  }

  function getServiceData({
    selectedDepartment,
    selectedService,
    serviceInfo,
  }) {
    let serviceId = selectedService || selectedDepartment

    let { requiredTime, typeOfService } = serviceInfo
    if (!requiredTime) {
      requiredTime = defaultEscalationTime
    }

    if (!typeOfService) {
      typeOfService = serviceType.serviceRequest
    }

    return { requiredTime, serviceId, typeOfService }
  }

  function getExtraInfoAndNoOfAllowedRequest({
    extendStayDays,
    extraBed,
    isGuestRequest,
    requestedTime,
    serviceInfo,
    serviceKey,
    userInfo,
  }) {
    let extraInfo = ''
    let noOfRequestsAllowed = 1

    switch (serviceKey) {
      case DepartmentAndServiceKeys.frontDesk.services.extraBed.key:
        extraInfo = `${extraBed} ${GetPostfix(extraBed, 'bed')}`
        break
      case DepartmentAndServiceKeys.frontDesk.services.extendStay.key:
        extraInfo = `${extendStayDays} ${GetPostfix(extendStayDays, 'day')}`
        break
      case DepartmentAndServiceKeys.frontDesk.services.airportDropoff.key:
      case DepartmentAndServiceKeys.frontDesk.services.wakeUpCall.key:
        extraInfo = moment(requestedTime).format('D MMM YYYY - hh:mm a')
        break
      case DepartmentAndServiceKeys.concierge.services.bookTaxi.key:
      case DepartmentAndServiceKeys.concierge.services.getMyCar.key:
      case DepartmentAndServiceKeys.foodAndBeverage.services.restaurant.key:
      case DepartmentAndServiceKeys.foodAndBeverage.services.roomService.key:
        noOfRequestsAllowed = 0
        break
      default:
        break
    }

    if (!isGuestRequest) {
      noOfRequestsAllowed = 0
    }
    const service = serviceInfo?.name || departmentWithOutServiceObj.name
    const fromDepartmentId = userInfo?.departmentId ?? ''
    const fromDepartmentName = userInfo?.department ?? ''
    const createdByName = userInfo?.name || ''

    return {
      createdByName,
      extraInfo,
      fromDepartmentId,
      fromDepartmentName,
      noOfRequestsAllowed,
      service,
    }
  }

  // Send manual request
  async function sendManualRequest(
    rNo,
    dep,
    serviceFromAi,
    formattedDate,
    day = '',
    bed = '',
    roomType = '',
    ticket_number = '',
    comment = ''
  ) {
    try {
      setLoading('Sending request...')

      if (loading) return

      const guestInfo = [
        {
          id: 'testGuestId',
          name: 'Test Guest',
          surName: '',
          roomNumber: '101',
          bookingReferance: 'TEST123',
        },
      ]

      const requestedTime = new Date(formattedDate)
      const currentTime = new Date()
      const validRequestedTime = isNaN(requestedTime.getTime())
        ? currentTime
        : requestedTime

      const dateTime = GetDateTimeString(validRequestedTime)

      const {
        createdByName,
        extraInfo,
        fromDepartmentId,
        fromDepartmentName,
        noOfRequestsAllowed,
        service,
      } = getExtraInfoAndNoOfAllowedRequest({
        extendStayDays: '1',
        extraBed: '1',
        isGuestRequest: false,
        requestedTime,
        serviceInfo: { name: serviceFromAi.service },
        serviceKey: serviceFromAi.serviceKey,
        userInfo,
      })

      const { requiredTime, serviceId, typeOfService } = getServiceData({
        selectedDepartment: fromDepartmentId,
        selectedService: serviceFromAi.serviceKey,
        serviceInfo: {
          requiredTime: '00:30',
          typeOfService: serviceType.serviceRequest,
        },
      })

      const department_type = dep.department_type

      const guestList = getGuestInfo({
        createdByName,
        fromDepartmentName,
        guestInfo,
        isGuestRequest: false,
      })

      const escalationTime = GetEscalationTime(validRequestedTime, requiredTime)

      const locationData = Object.values(locationIdToInfo).find(
        location => location.locationName === rNo
      )

      let requestData = {
        billAmount: 0,
        cuisines: [],
        dateTime: dateTime || '',
        department_type: dep.department_type || '',
        department: dep.department || '',
        departmentId: dep.departmentId || '',
        departmentKey: dep.departmentKey || '',
        departmentRequestImages: [],
        description: day || bed || moment(formattedDate).toDate(),
        escalationTime,
        extraInfo: day || bed || moment(formattedDate).toDate(),
        fromDepartmentId,
        fromDepartmentName: 'IVA AI',
        frontDeskServiceType: serviceFromAi.frontDeskServiceType || '',
        hotelId,
        isSubService: false,
        menuDetail: [],
        noOfGuest: '1',
        parentServiceId: '',
        parentServiceKey: '',
        requestDate: moment(formattedDate).toDate() || '',
        requestedDate: moment(formattedDate).toDate() || '',
        requestedTime: moment(formattedDate).toDate() || '',
        requestTime: moment(formattedDate).toDate() || '',
        requestType: 'Normal',
        requiredTime,
        restaurantId: '',
        restaurantName: '',
        roomType: roomType || '',
        service: serviceFromAi.service || '',
        serviceId: serviceFromAi.serviceId,
        serviceKey: serviceFromAi.serviceKey || '',
        serviceType: serviceFromAi.serviceType || '',
        status: pendingLable,
        ticketNumber: ticket_number || '',
        typeOfService,
        writeRequest: comment,
        assignedById: '',
        assignedByName: '',
        assignedToId: '',
        createdAtDate: moment(serverDate).startOf('day').toDate(),
        assignedToName: '',
        completedTime: null,
        createdByName,
        from: 'HOP',
        isEscalationNotificationSend: false,
        isGuestRequest: false,
        isReminderSend: false,
        isWarningSend: false,
        noOfRequestsAllowed,
        isMoreRequest: false,
        locationName: locationData?.locationName,
        locationId: locationData?.locationId,
        locationTypeId: locationData?.locationTypeId,
        locationTypeName: locationData?.locationTypeName,
        locationTypeisDefault: false,
        isLocationUsed: locationData?.isLocationUsed,

        ...creationData(serverDate),
      }

      const notificationData = {
        staffName: userInfo.name,
        staff_id: userInfo.id,
        departmentKey: dep.departmentKey || '',
        departmentId: dep.departmentId || '',
        service: serviceFromAi.service || '',
        currentLanguage,
      }

      // console.log(
      //   'Guest list: ',
      //   guestList,
      //   'Notification data: ',
      //   notificationData,
      //   'requestData',
      //   requestData
      // )

      const { success, message } = await saveServiceRequest({
        guestList,
        notificationData,
        requestData,
      })

      if (success) {
        AntdMessage.success(translateTextI18N('Request sent successfully'))
        return true
      } else {
        SetAutoClearProp(setError, message)
        return false
      }
    } catch (error) {
      SetAutoClearProp(setError, error?.message)
      return false
    } finally {
      setLoading('')
    }
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard title='IVA' breadcrumb={['Chat With IVA']} />
            </div>
          </div>
          <Layout style={styles.chatContainer}>
            <ChatHistory messages={messages} />
          </Layout>
          <div style={styles.inputContainer}>
            <Input
              style={styles.inputSearch}
              placeholder='How can I help you?'
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onPressEnter={handleUserInput}
            />
            <button style={styles.sendButton} onClick={handleUserInput}>
              Send
            </button>
          </div>
        </div>
      </section>
      <Modal
        title='Select Room Number'
        visible={popupState.showRoomNumber}
        onOk={handleRoomNumberConfirm}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='room-number-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please select the room number for this request:'
                  name='roomNumber'
                  rules={[
                    { required: true, message: 'Please select a room number' },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Select room number'
                    value={roomNumber}
                    onChange={value => {
                      setRoomNumber(value)
                      setInvalidRoomNumber(false)
                    }}
                  >
                    {roomNumbers.map(roomNum => (
                      <Option key={roomNum} value={roomNum}>
                        {roomNum}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {invalidRoomNumber && (
                  <div className='warning-message' style={{ color: 'red' }}>
                    Invalid Room number. Please choose a valid Room number.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={handleRoomNumberConfirm}
              disabled={!roomNumber}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title='Select Room Type'
        visible={popupState.showRoomType}
        onOk={handleRoomTypeConfirm}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='room-type-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please select the room type for this request:'
                  name='roomType'
                  rules={[
                    { required: true, message: 'Please select a room type' },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Select room type'
                    value={roomType}
                    onChange={value => {
                      setRoomType(value)
                      setInvalidRoomType(false)
                    }}
                  >
                    {roomsData.map(room => (
                      <Option key={room.id} value={room.id}>
                        {room.roomName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {invalidRoomType && (
                  <div className='warning-message' style={{ color: 'red' }}>
                    Invalid Room type. Please select a valid Room type.
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={handleRoomTypeConfirm}
              disabled={!roomType}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title='Confirm Request'
        visible={popupState.showConfirmation}
        onOk={handleConfirm}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <p>Are you sure you want to send this request?</p>

          <div className='row' id='confirmation-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item label='Comments' name='comment'>
                  <TextArea
                    rows={4}
                    placeholder='Write comments...'
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title='Enter Ticket Number'
        visible={popupState.showTicketNumber}
        onOk={handleTicketNumberConfirm}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='ticket-number-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please enter the ticket number for this request:'
                  name='ticketNumber'
                  rules={[
                    { required: true, message: 'Please enter a ticket number' },
                  ]}
                >
                  <Input
                    placeholder='Ticket number'
                    value={ticketNumber}
                    onChange={e => setTicketNumber(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={handleTicketNumberConfirm}
              disabled={!ticketNumber}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title='Enter Days to Extend'
        visible={popupState.showDaysToExtend}
        onOk={handleDaysToExtendConfirm}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='days-to-extend-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please enter the number of days to extend your stay:'
                  name='daysToExtend'
                >
                  <Input
                    placeholder='Number of days'
                    value={daysToExtend}
                    onChange={e => setDaysToExtend(e.target.value)}
                    type='number'
                    min={1}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={handleDaysToExtendConfirm}
              disabled={!daysToExtend}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title='Enter Number of Beds'
        visible={popupState.showNumberOfBeds}
        onOk={handleNumberOfBedsConfirm}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='number-of-beds-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please enter the number of extra beds needed:'
                  name='numberOfBeds'
                >
                  <Input
                    placeholder='Number of beds'
                    value={numberOfBeds}
                    onChange={e => setNumberOfBeds(e.target.value)}
                    type='number'
                    min={1}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={handleNumberOfBedsConfirm}
              disabled={!numberOfBeds}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title='Select Date and Time'
        visible={popupState.showDateTime}
        onOk={() => {
          const dateTime = document.getElementById('datePicker').value
          handleDateTimeConfirm(dateTime)
        }}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='date-time-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please select the date and time for this request:'
                  name='dateTime'
                  rules={[
                    { required: true, message: 'Please select date and time' },
                  ]}
                >
                  <DatePicker
                    id='datePicker'
                    showTime={{
                      format: 'HH:mm',
                      use12Hours: true,
                    }}
                    format='YYYY-MM-DD HH:mm'
                    style={{ width: '100%' }}
                    placeholder='Select date and time'
                    onChange={date => {
                      setSelectedDateBtn(false)
                      if (date) {
                        setInvalidDate(false)
                        setPastDate(
                          date
                            ? moment(date).add(1, 'minutes').isBefore(moment())
                            : false
                        )
                      }
                    }}
                  />
                </Form.Item>
                {invalidDate && (
                  <div className='warning-message'>
                    <div style={{ color: 'red' }}>Invalid date format.</div>
                    <div style={{ color: 'black' }}>
                      Please enter the date and time in the correct format
                      (e.g., 5th September 5pm).
                    </div>
                  </div>
                )}
                {pastDate && (
                  <div className='warning-message'>
                    <div style={{ color: 'red' }}>
                      Invalid date and time selected.
                    </div>
                    <div style={{ color: 'black' }}>
                      Please select a date and time in the future.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={() => {
                const dateTime = document.getElementById('datePicker').value
                handleDateTimeConfirm(dateTime)
              }}
              disabled={pastDate || invalidDate || selectedDateBtn}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title='Select Time'
        visible={popupState.showTimeOnly}
        onOk={() => {
          const time = document.getElementById('timePicker').value
          handleTimeConfirm(time)
        }}
        onCancel={handleCancel}
        centered
        className='cmnModal'
        footer={null}
        maskClosable={false}
        afterClose={resetForm}
      >
        <Form form={form} layout='vertical'>
          <div className='row' id='time-form'>
            <div className='col-12 col-md-12'>
              <div className='form-group cmn-input'>
                <Form.Item
                  label='Please select the time for this request:'
                  name='time'
                  rules={[{ required: true, message: 'Please select time' }]}
                >
                  <TimePicker
                    id='timePicker'
                    format='HH:mm'
                    use12Hours
                    style={{ width: '100%' }}
                    placeholder='Select time'
                    onChange={time => {
                      setSelectedTimeBtn(false)
                      setPastTime(
                        time
                          ? moment(time, 'HH:mm')
                              .add(1, 'minutes')
                              .isBefore(moment())
                          : false
                      )
                    }}
                  />
                </Form.Item>
                {pastTime && (
                  <div className='warning-message'>
                    <div style={{ color: 'red' }}>Invalid Time selected.</div>
                    <div style={{ color: 'black' }}>
                      Please select a time in the future.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='modalFooter'>
            <Button className='grayBtn' key='back' onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className='blueBtn ml-3 ml-lg-4'
              key='submit'
              onClick={() => {
                const time = document.getElementById('timePicker').value
                handleTimeConfirm(time)
              }}
              disabled={pastTime || selectedTimeBtn}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default ChatWithIva
