import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Input, Tooltip, Popconfirm, message } from 'antd'
import {
  SendOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import firebase from 'firebase/app'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useCustomI18NTranslatorHook } from '../../../../../utility/globalization'

const { TextArea } = Input

const TaskComments = ({
  visible,
  onCancel,
  task,
  isStatusChange,
  statusChangeComment,
  setStatusChangeComment,
  onStatusChange,
  changingStatus,
  getTitleFunction,
}) => {
  const [form] = Form.useForm()
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedComment, setSelectedComment] = useState(null)
  const { hotelInfo } = useSelector(state => state)

  useEffect(() => {
    if (visible && task) {
      fetchComments()
    }
  }, [visible, task])

  //fetchCommments logic in firebase query move
  const fetchComments = async () => {
    try {
      const db = firebase.firestore()
      const taskDoc = await db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')
        .doc(task.key)
        .get()
      if (taskDoc.exists) {
        setComments(taskDoc.data().comments || [])
      }
    } catch (error) {
      console.error('Error fetching comments:', error)
    }
  }

  const handleAddComment = async values => {
    try {
      setLoading(true)
      if (isStatusChange) {
        await onStatusChange(task.key, changingStatus, values.comment)
      } else {
        const newComment = {
          id: uuidv4(),
          staffId: task.staffId,
          staffName: task.staffName,
          date: firebase.firestore.Timestamp.now(),
          description: values.comment,
          type: 'Commented',
        }

        const db = firebase.firestore()
        await db
          .collection('tasks')
          .doc(hotelInfo.hotelId)
          .collection('hotelTasks')
          .doc(task.key)
          .update({
            comments: firebase.firestore.FieldValue.arrayUnion(newComment),
          })

        setComments(prevComments => [...prevComments, newComment])
        form.resetFields()
        message.success({
          icon: <CheckCircleFilled style={{ color: 'lightgreen' }} />,
          content: 'Comment added successfully',
        })
      }
    } catch (error) {
      console.error('Error adding comment:', error)
      message.error(
        isStatusChange ? 'Failed to update status' : 'Failed to add comment'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleEditComment = async () => {
    try {
      setLoading(true)
      const updatedComment = {
        ...selectedComment,
        description: form.getFieldValue('comment'),
        date: firebase.firestore.Timestamp.now(),
        type: 'Updated',
      }

      const updatedComments = comments.map(c =>
        c.id === updatedComment.id ? updatedComment : c
      )

      const db = firebase.firestore()
      await db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')
        .doc(task.key)
        .update({ comments: updatedComments })

      setComments(updatedComments)
      resetForm()
      message.success({
        icon: <CheckCircleFilled style={{ color: 'lightgreen' }} />,
        content: 'Comment updated successfully',
      })
    } catch (error) {
      console.error('Error updating comment:', error)
      message.error('Failed to update comment')
    } finally {
      setLoading(false)
    }
  }

  //Move to firebase query
  const handleDeleteComment = async comment => {
    try {
      setLoading(true)
      const updatedComments = comments.filter(c => c.id !== comment.id)

      const db = firebase.firestore()
      await db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')
        .doc(task.key)
        .update({ comments: updatedComments })

      setComments(updatedComments)
      message.success({
        icon: <CheckCircleFilled style={{ color: 'lightgreen' }} />,
        content: 'Comment deleted successfully',
      })
    } catch (error) {
      console.error('Error deleting comment:', error)
      message.error('Failed to delete comment')
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    form.resetFields()
    setEditMode(false)
    setSelectedComment(null)
  }

  const CommentItem = ({ comment }) => (
    <div className='comment-timeline'>
      {comment.type === 'Note' ? (
        <>
          <b className='commentedName'>Notes</b>
          <p>{comment.description}</p>
        </>
      ) : (
        <>
          <b className='commentedName'>
            {comment.staffId === task.staffId ? 'You' : comment.staffName}
          </b>
          {comment.type.startsWith('Status changed') ? (
            <span>{comment.type} on </span>
          ) : (
            <span>
              {comment.type === 'Commented'
                ? ' commented on '
                : ' updated comment on '}
            </span>
          )}
          <b className='commentedDate'>
            {moment(comment.date.toDate()).format('DD MMM YYYY - hh:mm A')}
          </b>
          <p>{comment.description}</p>
          {comment.staffId === task.staffId &&
            !comment.type.startsWith('Status changed') && (
              <div style={{ float: 'right' }}>
                <Tooltip title='Edit comment'>
                  <EditOutlined
                    className='viewlink'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setEditMode(true)
                      setSelectedComment(comment)
                      form.setFieldsValue({ comment: comment.description })
                    }}
                  />
                </Tooltip>
                <Popconfirm
                  placement='top'
                  title='Are you sure to delete this comment?'
                  onConfirm={() => handleDeleteComment(comment)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteOutlined
                    className='viewlink'
                    style={{ cursor: 'pointer' }}
                  />
                </Popconfirm>
              </div>
            )}
        </>
      )}
    </div>
  )

  const constructTitle = () => {
    let title = task?.service || 'Task'

    if (task?.location) {
      title += ` - Room/Location: ${task.location}`
    }

    if (task?.staffName) {
      title += ` - Reported By: ${task.staffName}`
    }

    if (task?.department) {
      title += ` (${task.department})`
    }

    return title
  }

  const modalTitle = getTitleFunction
    ? getTitleFunction(task)
    : constructTitle()

  return (
    <Modal
      className='cmnModal commentsModal commentsViewModal'
      title={<span style={{ fontSize: '18px' }}>{modalTitle}</span>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={true}
    >
      <div className='form-group cmnt-view-add-inline'>
        <Form
          form={form}
          onFinish={editMode ? handleEditComment : handleAddComment}
          layout='inline'
          style={{ alignItems: 'center' }}
        >
          <Form.Item
            name='comment'
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject('Comment is mandatory!')
                  }
                  return Promise.resolve()
                },
              },
            ]}
            style={{ width: editMode ? '88%' : '92%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextArea
                rows={2}
                maxLength={250}
                placeholder={translateTextI18N('Max 250 Characters...')}
                value={isStatusChange ? statusChangeComment : undefined}
                onChange={
                  isStatusChange
                    ? e => setStatusChangeComment(e.target.value)
                    : undefined
                }
              />
              {editMode && !isStatusChange && (
                <Button
                  onClick={resetForm}
                  className='viewlink'
                  icon={<CloseOutlined style={{ color: 'white' }} />}
                  type='primary'
                />
              )}
              <Button
                htmlType='submit'
                className='viewlink'
                icon={<SendOutlined style={{ color: 'white' }} />}
                type='primary'
                loading={loading}
              />
            </div>
          </Form.Item>
        </Form>
      </div>

      <div style={{ height: '400px', overflowY: 'scroll' }}>
        {comments
          .sort((a, b) => b.date.toDate() - a.date.toDate())
          .map(comment => (
            <CommentItem key={comment.id} comment={comment} />
          ))}
      </div>

      {!isStatusChange && (
        <div className='modalFooter-center'>
          <Button className='blueBtn' onClick={onCancel}>
            {translateTextI18N('Close')}
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default TaskComments
