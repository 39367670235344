import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  Table,
  Button,
  Menu,
  Dropdown,
  Select,
  DatePicker,
  message,
  Tooltip,
} from 'antd'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { useLocation } from 'react-router-dom'
import Header from '../../../../Common/Header/Header'
import SideMenu from '../../../../Common/Sidemenu/Sidemenu'
import PageNameCard from '../../../../Common/PageNameCard/PageNameCard'
import { Search } from '../../../../../config/constants'
import moment from 'moment'
import {
  getImage,
  getStatusButtonStyle,
  handleStatusChangeWithComment,
  updateTasksState,
  getCustomTitle,
} from '../../../../../config/utils'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../../../../../Store'
import TaskComments from './TaskComments'
import { DownOutlined, CommentOutlined } from '@ant-design/icons'
import ReassignTask from './ReassignTask'
import { getStaffs } from '../../../../../services/user'

const { Option } = Select

const HouseKeepingTimeScheduler = () => {
  const [tasks, setTasks] = useState([])
  const [reassignModalVisible, setReassignModalVisible] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const {
    housekeepingStaff,
    hotelId,
    hotelInfo,
    userInfo,
    recurringPermissions,
  } = useSelector(state => state)

  const [staffList, setStaffList] = useState([])
  const location = useLocation()
  const tableRef = useRef(null)
  const [highlightedTaskId, setHighlightedTaskId] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [commentModalVisible, setCommentModalVisible] = useState(false)
  const [statusChangeComment, setStatusChangeComment] = useState('')
  const [changingStatus, setChangingStatus] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState(null)
  const [filterDate, setFilterDate] = useState('all')
  const [departments, setDepartments] = useState([])
  const [listOfStaff, setListOfStaff] = useState([])

  const listView = recurringPermissions?.canListView || ''

  const taskSheetManagementView =
    recurringPermissions?.canTaskSheetManagementView
  let currentStaffName = ''

  let isTaskSheet = null
  let isListView = null

  if ((taskSheetManagementView && listView) || taskSheetManagementView) {
    isTaskSheet = true
    isListView = false
  } else if (listView) {
    isListView = true
  }

  if (isTaskSheet) {
    currentStaffName = ''
  } else {
    currentStaffName = userInfo?.name
  }

  console.log(staffList);
  
  if (userInfo.level === 0) {
    currentStaffName = ''
  }

  const [searchStaff, setSearchStaff] = useState(currentStaffName)

  useEffect(() => {
    const fetchStaffList = async () => {
      try {
        const list = await getStaffs(hotelId)
        setStaffList(list)
      } catch (error) {
        console.error('Error fetching staff list:', error)
      }
    }

    fetchStaffList()
  }, [hotelId])

  const parseDateString = dateString => {
    const parsed = moment(dateString, 'DD-MM-YYYY', true)
    return parsed.isValid() ? parsed : moment(0)
  }

  const fetchDepartments = useCallback(async () => {
    try {
      const db = firebase.firestore()
      const hotelTasksRef = db.collection('tasks').doc(hotelInfo.hotelId)
      const doc = await hotelTasksRef.get()

      if (doc.exists && doc.data().services) {
        const servicesData = doc.data().services
        const activeDepartments = servicesData
          .filter(s => s.status !== false)
          .map(s => s.department)
        const departmentsSet = new Set(activeDepartments)
        setDepartments(Array.from(departmentsSet))
      } else {
        setDepartments([])
      }
    } catch (error) {
      console.error('Error fetching departments:', error)
    }
  }, [hotelInfo.hotelId])

  useEffect(() => {
    fetchDepartments()
  }, [fetchDepartments])

  const sortTasksByDate = (a, b) => {
    const dateA = parseDateString(a.scheduleDate)
    const dateB = parseDateString(b.scheduleDate)
    return dateA.diff(dateB)
  }
  //move to firebase query fun file
  const updateStatus = useCallback(
    async (taskId, newStatus) => {
      try {
        const db = firebase.firestore()
        const taskRef = db
          .collection('tasks')
          .doc(hotelInfo.hotelId)
          .collection('hotelTasks')
          .doc(taskId)

        const currentTime = moment().format('hh:mm A')
        const currentDate = moment().format('DD-MM-YYYY')

        let updateObj = { status: newStatus }
        if (newStatus === 'In Progress') {
          updateObj = { ...updateObj, startTime: currentTime }
        } else if (newStatus === 'Clean') {
          updateObj = {
            ...updateObj,
            completedTime: currentTime,
            completeddate: currentDate,
          }
        }

        await taskRef.update(updateObj)
        setTasks(prevTasks =>
          prevTasks.map(task =>
            task.key === taskId ? { ...task, ...updateObj } : task
          )
        )
      } catch (error) {
        console.error('Error updating status:', error)
      }
    },
    [hotelInfo.hotelId]
  )

  const fetchServices = useCallback(
    async department => {
      try {
        const db = firebase.firestore()
        const hotelTasksRef = db.collection('tasks').doc(hotelInfo.hotelId)
        const doc = await hotelTasksRef.get()

        if (doc.exists && doc.data().services) {
          const servicesData = doc.data().services
          let filteredServices
          if (department) {
            filteredServices = servicesData
              .filter(s => s.department === department && s.status !== false)
              .map(s => s.service)
          } else {
            filteredServices = servicesData
              .filter(s => s.status !== false)
              .map(s => s.service)
          }
          setServices(filteredServices)
        } else {
          setServices([])
        }
      } catch (error) {
        console.error('Error fetching services:', error)
      }
    },
    [hotelInfo.hotelId]
  )

  useEffect(() => {
    fetchServices(selectedDepartment)
  }, [selectedDepartment, fetchServices])

  const checkAndUpdateStatus = useCallback(task => {
    const now = moment()
    const scheduleDate = parseDateString(task.scheduleDate)

    if (scheduleDate.isBefore(now, 'day') && task.status !== 'Clean') {
      return { ...task, status: 'Pending' }
    }
    return task
  }, [])
  //use this function in firebase query file
  const fetchTasks = useCallback(
    async taskId => {
      try {
        const db = firebase.firestore()
        const snapshot = await db
          .collection('tasks')
          .doc(hotelInfo.hotelId)
          .collection('hotelTasks')
          .get()
        const fetchedTasks = snapshot.docs.map(doc => ({
          key: doc.id,
          ...doc.data(),
          location: doc.data().roomNumbers
            ? doc.data().roomNumbers.join(', ')
            : '',
          completedTime: doc.data().completedTime || '',
          completeddate: doc.data().completeddate || '',
        }))

        const updatedTasks = fetchedTasks.map(checkAndUpdateStatus)
        const sortedTasks = updatedTasks.sort(sortTasksByDate)
        setTasks(sortedTasks)
        setLoading(false)

        // if (taskId) {
        //   const index = fetchedTasks.findIndex(item => item.key === taskId)
        //   if (index !== -1) {
        //     const page = Math.floor(index / PaginationOptions.pageSize) + 1
        //     setCurrentPage(page)
        //   }
        // }

        updatedTasks.forEach(task => {
          const originalTask = fetchedTasks.find(t => t.key === task.key)
          if (task.status !== originalTask.status) {
            updateStatus(task.key, task.status)
          }
        })
      } catch (error) {
        console.error('Error fetching tasks:', error)
      }
    },
    [checkAndUpdateStatus, updateStatus, hotelInfo.hotelId]
  )
  //staff changing here
  //code not refeclting in qa when deployment
  useEffect(() => {
    if (staffList.length > 0 && housekeepingStaff.length === 0) {
      const filteredStaff = staffList
        .filter(
          staff =>
            staff.department === userInfo.department &&
            staff.status === 'active' &&
            staff.id
        )
        .map(staff => ({ value: staff.id, label: staff.name }))

      dispatch(actions.setHousekeepingStaff(filteredStaff))
    }
  }, [staffList, dispatch])

  //filtered staff for hotelAdmin
  useEffect(() => {
    if (staffList.length > 0 && userInfo.level === 0) {
      const filteredStaffList = staffList
        .filter(staff => staff.status === 'active')
        .map(staff => ({
          value: staff.id,
          label: staff.name,
        }))

      setListOfStaff(filteredStaffList)
    }
  }, [staffList])

  useEffect(() => {
    dispatch(actions.setSideMenuSelectedKey('1446'))

    const taskId = new URLSearchParams(location.search).get('id')
    setHighlightedTaskId(taskId)
    fetchTasks(taskId)

    // Set current user
    const user = firebase.auth().currentUser
    if (user) {
      setCurrentUser({
        uid: user.uid,
        displayName: user.displayName,
      })
    }
    //bad code need to change high pripority
    const interval = setInterval(fetchTasks, 60000) // Check every minute
    return () => clearInterval(interval)
  }, [fetchTasks, location.search, dispatch])

  useEffect(() => {
    if (!loading && highlightedTaskId && tableRef.current) {
      const row = tableRef.current.querySelector(
        `tr[data-row-key="${highlightedTaskId}"]`
      )
      if (row) {
        const cells = row.querySelectorAll('td')
        cells.forEach(cell => {
          cell.classList.add('highlight-blink')
        })
        setTimeout(() => {
          cells.forEach(cell => {
            cell.classList.remove('highlight-blink')
          })
        }, 10000)
      }
    }
  }, [highlightedTaskId, loading])

  const showReassignModal = task => {
    setSelectedTask(task)
    setReassignModalVisible(true)
  }

  //move this to firebase service query file
  const handleReassign = async (taskId, newStaff) => {
    try {
      const db = firebase.firestore()
      const taskDoc = await db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')
        .doc(taskId)
        .get()

      const taskData = taskDoc.data()
      const scheduleDate = taskData.scheduleDate

      await db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')
        .doc(taskId)
        .update({
          staffName: newStaff.name,
          staffId: newStaff.id,
          status: 'Pending',
          startDate: scheduleDate,
          endDate: scheduleDate,
        })

      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.key === taskId
            ? {
                ...task,
                staffName: newStaff.name,
                staffId: newStaff.id,
                status: 'Pending',
                startDate: scheduleDate,
                endDate: scheduleDate,
              }
            : task
        )
      )

      message.success('Task reassigned successfully')
    } catch (error) {
      console.error('Error reassigning task:', error)
      message.error('Failed to reassign task')
    }
  }

  const handleDepartmentChange = value => {
    setSelectedDepartment(value)
    setSelectedService(null)
    fetchServices(value)
  }

  const handleServiceChange = value => {
    setSelectedService(value)
  }

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleStatusChange = value => {
    setSelectedStatus(value)
  }

  const resetFilters = () => {
    setSearchStaff('')
    setSelectedDepartment(null)
    setSelectedDate(null)
    setSelectedStatus(null)
    setSelectedService(null)
    setFilterDate('all')
    fetchServices(null)
  }

  const isToday = dateString => {
    const today = moment().format('DD-MM-YYYY')
    return dateString === today
  }

  const filteredTasks = tasks.filter(task => {
    const staffNameMatch =
      !searchStaff ||
      task.staffName.toLowerCase().includes(searchStaff.toLowerCase())
    const departmentMatch =
      !selectedDepartment || task.department === selectedDepartment
    const dateMatch =
      !selectedDate ||
      moment(task.scheduleDate, 'DD-MM-YYYY').isSame(selectedDate, 'day')
    const statusMatch = !selectedStatus || task.status === selectedStatus
    const serviceMatch = !selectedService || task.service === selectedService
    const filterDateMatch =
      filterDate === 'all' ||
      (filterDate === 'today' && isToday(task.scheduleDate))

    return (
      staffNameMatch &&
      departmentMatch &&
      dateMatch &&
      statusMatch &&
      serviceMatch &&
      filterDateMatch
    )
  })

  const handleStatusChangeWithCommentWrapper = async (
    taskId,
    newStatus,
    comment
  ) => {
    try {
      const db = firebase.firestore()
      const taskToUpdate = tasks.find(task => task.key === taskId)
      if (!taskToUpdate) {
        throw new Error('Task not found')
      }
      const newComment = await handleStatusChangeWithComment(
        taskId,
        newStatus,
        comment,
        currentUser,
        db,
        hotelInfo.hotelId
      )
      setTasks(prevTasks =>
        updateTasksState(prevTasks, taskId, newStatus, newComment)
      )
      setCommentModalVisible(false)
      setChangingStatus(null)
      setStatusChangeComment('')
      message.success(`Status updated to ${newStatus}`)
    } catch (error) {
      console.error('Error updating status with comment:', error)
      message.error('Failed to update status')
    }
  }

  const showCommentModal = task => {
    setSelectedTask({
      ...task,
      staffId: task.staffId,
      staffName: task.staffName,
    })
    setCommentModalVisible(true)
  }

  const statusMenu = task => {
    if (task.status.toLowerCase() === 'clean') {
      return null
    }

    const statuses = [
      'In Progress',
      'Clean',
      'Pending',
      'DND',
      'Out Of Service',
      'Delayed',
      'CheckOut',
    ]

    return (
      <Menu
        onClick={({ key }) => {
          if (['DND', 'Out Of Service', 'Delayed', 'CheckOut'].includes(key)) {
            setSelectedTask(task)
            setChangingStatus(key)
            setCommentModalVisible(true)
          } else {
            updateStatus(task.key, key)
          }
        }}
      >
        {statuses.map(status => (
          <Menu.Item key={status}>
            <span>{status}</span>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  const taskColumns = [
    { title: 'Location', dataIndex: 'location', width: 100 },
    { title: 'Assigned Staff', dataIndex: 'staffName', width: 100 },
    { title: 'Department', dataIndex: 'department', width: 100 },
    { title: 'Service', dataIndex: 'service', width: 100 },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (_, record) => {
        if (record.status.toLowerCase() === 'clean') {
          return (
            <Button
              className={`statusBtn ant-btn-${record.status
                .toLowerCase()
                .replace(/ /g, '-')}`}
              style={getStatusButtonStyle(record.status)}
            >
              <span>{record.status}</span>
            </Button>
          )
        }
        return (
          <Tooltip
            title={
              !isToday(record.scheduleDate)
                ? 'Cannot change status for this date'
                : ''
            }
          >
            <span>
              <Dropdown
                overlay={statusMenu(record)}
                trigger={['click']}
                disabled={!isToday(record.scheduleDate)}
              >
                <Button
                  className={`statusBtn ant-btn-${record.status
                    .toLowerCase()
                    .replace(/ /g, '-')}`}
                  style={getStatusButtonStyle(record.status)}
                  disabled={!isToday(record.scheduleDate)}
                >
                  <span>{record.status}</span>
                  <DownOutlined
                    style={{ fontSize: '10px', marginLeft: '3px' }}
                  />
                </Button>
              </Dropdown>
            </span>
          </Tooltip>
        )
      },
    },
    {
      title: 'Schedule Date',
      dataIndex: 'scheduleDate',
      width: 100,
      sorter: false,
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      width: 100,
      render: startTime =>
        startTime ? moment(startTime, 'hh:mm A').format('hh:mm A') : '',
    },
    {
      title: 'Completed Time',
      dataIndex: 'completedTime',
      width: 100,
      render: completedTime =>
        completedTime ? moment(completedTime, 'hh:mm A').format('hh:mm A') : '',
    },
    ...(!isListView
      ? [
          {
            title: 'Reassign',
            dataIndex: 'reassign',
            width: 100,
            render: (_, record) => (
              <Button
                className='statusBtn completedBtn'
                onClick={() => showReassignModal(record)}
              >
                Reassign
              </Button>
            ),
          },
        ]
      : []),
    {
      title: 'Comments',
      dataIndex: 'comments',
      width: 100,
      render: (_, record) => (
        <CommentOutlined
          className='viewlink'
          style={{ fontSize: 20, cursor: 'pointer' }}
          onClick={() => showCommentModal(record)}
        />
      ),
    },
  ]

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNameCard
                title='List View'
                breadcrumb={['Hotel Admin', 'List View']}
              />
              <div className='mt-3'>
                <Button
                  type={filterDate === 'today' ? 'primary' : 'default'}
                  onClick={() => setFilterDate('today')}
                  className='cmnBtn'
                  style={{ marginRight: '10px', marginBottom: '10px' }}
                >
                  Today
                </Button>
                <Button
                  type={filterDate === 'all' ? 'primary' : 'default'}
                  onClick={() => setFilterDate('all')}
                  className='cmnBtn'
                >
                  All
                </Button>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  {/* Conditionally render the search staff input */}
                  {!isListView && (
                    <div className='col-4 col-md-3'>
                      <div className='searchbox'>
                        <Search
                          placeholder={'Search by Staff'}
                          value={searchStaff}
                          onChange={e => setSearchStaff(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <div className='col-4 col-md-auto'>
                    <div className='cmnSelect-form' id='drpDept'>
                      <Select
                        showSearch
                        placeholder='Department'
                        optionFilterProp='children'
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                      >
                        {departments.map(dept => (
                          <Option key={dept} value={dept}>
                            {dept}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md-auto'>
                    <div className='cmnSelect-form'>
                      <Select
                        showSearch
                        placeholder='Service'
                        optionFilterProp='children'
                        value={selectedService}
                        onChange={handleServiceChange}
                      >
                        {services.map(service => (
                          <Option key={service} value={service}>
                            {service}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md-2'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        placeholder={'Schedule Date'}
                        onChange={handleDateChange}
                        format='DD-MM-YYYY'
                      />
                    </div>
                  </div>
                  <div className='col-4 col-md-auto'>
                    <div className='cmnSelect-form'>
                      <Select
                        placeholder='Status'
                        value={selectedStatus}
                        onChange={handleStatusChange}
                      >
                        <Option value='Clean'>Clean</Option>
                        <Option value='In Progress'>In Progress</Option>
                        <Option value='Pending'>Pending</Option>
                        <Option value='DND'>DND</Option>
                        <Option value='Out Of Service'>Out Of Service</Option>
                        <Option value='Delayed'>Delayed</Option>
                        <Option value='CheckOut'>CheckOut</Option>
                      </Select>
                    </div>
                  </div>

                  <div className='col-6 col-md-auto '>
                    <Button
                      type='primary'
                      title='Reset Filter'
                      className='adduserbtn'
                      onClick={resetFilters}
                    >
                      <img src={getImage('images/clearicon.svg')} alt='' />
                    </Button>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={taskColumns}
                      dataSource={filteredTasks}
                      // pagination={{
                      //   ...PaginationOptions,
                      //   current: currentPage,
                      //   onChange: page => setCurrentPage(page),
                      // }}
                      loading={loading}
                      scroll={{ y: 382 }}
                      rowKey='key'
                      ref={tableRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReassignTask
        visible={reassignModalVisible}
        onCancel={() => setReassignModalVisible(false)}
        task={selectedTask}
        onReassign={handleReassign}
        staffList={userInfo.level === 0 ? listOfStaff : housekeepingStaff}
      />
      <TaskComments
        visible={commentModalVisible}
        onCancel={() => {
          setCommentModalVisible(false)
          setChangingStatus(null)
          setStatusChangeComment('')
        }}
        task={selectedTask}
        isStatusChange={!!changingStatus}
        statusChangeComment={statusChangeComment}
        setStatusChangeComment={setStatusChangeComment}
        onStatusChange={handleStatusChangeWithCommentWrapper}
        changingStatus={changingStatus}
        getTitleFunction={getCustomTitle}
      />
      <style jsx>{`
        @keyframes blink {
          0%,
          100% {
            background-color: #adadad;
          }
        }
        .highlight-blink {
          animation: blink 0.5s step-start 10;
        }
      `}</style>
    </>
  )
}

export default HouseKeepingTimeScheduler
