import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Button, Table, Select, DatePicker, Modal, message, Form } from 'antd'
import { useSelector } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useLocation } from 'react-router-dom'
import Header from '../../../../Common/Header/Header'
import SideMenu from '../../../../Common/Sidemenu/Sidemenu'
import ScheduleTaskModal from './ScheduleTaskModal'
// import { actions } from '../../../../../Store'
import { PaginationOptions, Search } from '../../../../../config/constants'
import PageNameCard from '../../../../Common/PageNameCard/PageNameCard'
import { getImage } from '../../../../../config/utils'
import moment from 'moment'
import DeleteModal from '../../../../Common/Modals/DeleteModal'
import DepartmentAndServiceKeys from '../../../../../config/departmentAndServicekeys'
import SuccessModal from '../../../../Common/Modals/SuccessModal'

const { Option } = Select

const RecurringTaskScheduler = () => {
  // const dispatch = useDispatch()
  const [form] = Form.useForm()
  const tableRef = useRef(null)

  const [searchStaff, setSearchStaff] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [services, setServices] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [deleteTaskId, setDeleteTaskId] = useState(null)
  const location = useLocation()
  const [highlightedTaskId, setHighlightedTaskId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [tasks, setTasks] = useState([])
  const [departments, setDepartments] = useState([])
  const [editingTask, setEditingTask] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const { hotelInfo } = useSelector(state => state)

  useEffect(() => {
    // dispatch(actions.setSideMenuSelectedKey(['sub4', '1221'])) // Assuming 'sub4' is the parent key and '1221' is the item key
    // dispatch(actions.setSideMenuOpenKeys('')) // Open the parent submenu
    const taskId = new URLSearchParams(location.search).get('id')
    setHighlightedTaskId(taskId)
    fetchTasks(taskId)
    loadDepartments()
  }, [location.search])

  const loadDepartments = () => {
    const depts = Object.entries(DepartmentAndServiceKeys)
      .filter(
        ([key, dep]) =>
          dep.name &&
          dep.key &&
          dep.services &&
          Object.keys(dep.services).length > 0
      )
      .map(([key, dep]) => ({ name: dep.name, key: key }))
    setDepartments(depts)
  }

  const fetchServices = useCallback(
    async department => {
      try {
        const db = firebase.firestore()
        const hotelTasksRef = db.collection('tasks').doc(hotelInfo.hotelId)
        const doc = await hotelTasksRef.get()

        if (doc.exists && doc.data().services) {
          const servicesData = doc.data().services
          let filteredServices
          if (department) {
            filteredServices = servicesData
              .filter(s => s.department === department && s.status !== false)
              .map(s => s.service)
          } else {
            filteredServices = servicesData
              .filter(s => s.status !== false)
              .map(s => s.service)
          }
          setServices(filteredServices)
        } else {
          setServices([])
        }
      } catch (error) {
        console.error('Error fetching services:', error)
      }
    },
    [hotelInfo.hotelId]
  )

  useEffect(() => {
    fetchServices(selectedDepartment)
  }, [selectedDepartment, fetchServices])

  const fetchTasks = async taskId => {
    try {
      const db = firebase.firestore()
      const snapshot = await db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')
        .get()

      const fetchedTasks = snapshot.docs.map(doc => ({
        id: doc.id,
        assignedStaff: doc.data().staffName || '',
        startDate: doc.data().startDate || doc.data().scheduleDate || '',
        endDate: doc.data().endDate || doc.data().scheduleDate || '',
        department: doc.data().department || '',
        service: doc.data().service || '',
        roomNumbers: doc.data().roomNumbers || [],
        staffId: doc.data().staffId || '',
        note: doc.data().note || '',
      }))

      // Group tasks by their unique identifiers
      const groupedTasks = fetchedTasks.reduce((acc, task) => {
        const key = `${task.staffId}-${task.startDate}-${task.endDate}-${task.department}-${task.service}`
        if (!acc[key]) {
          acc[key] = { ...task, roomNumbers: new Set(task.roomNumbers) }
        } else {
          task.roomNumbers.forEach(room => acc[key].roomNumbers.add(room))
        }
        return acc
      }, {})

      // Convert Set to Array for roomNumbers
      const consolidatedTasks = Object.values(groupedTasks).map(task => ({
        ...task,
        roomNumbers: Array.from(task.roomNumbers),
        location: Array.from(task.roomNumbers).join(', '),
      }))

      setTasks(consolidatedTasks)
      setLoading(false)

      if (taskId) {
        const index = consolidatedTasks.findIndex(item => item.id === taskId)
        if (index !== -1) {
          const page = Math.floor(index / PaginationOptions.pageSize) + 1
          setCurrentPage(page)
        }
      }
    } catch (error) {
      console.error('Error fetching tasks:', error)
      message.error('Failed to fetch tasks')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading && highlightedTaskId && tableRef.current) {
      const row = tableRef.current.querySelector(
        `tr[data-row-key="${highlightedTaskId}"]`
      )
      if (row) {
        const cells = row.querySelectorAll('td')
        cells.forEach(cell => {
          cell.classList.add('highlight-blink')
        })
        setTimeout(() => {
          cells.forEach(cell => {
            cell.classList.remove('highlight-blink')
          })
        }, 4000)
      }
    }
  }, [highlightedTaskId, currentPage, loading])

  const handleTableChange = pagination => {
    setCurrentPage(pagination.current)
  }

  const taskColumns = [
    {
      title: 'Assigned Staff',
      dataIndex: 'assignedStaff',
      width: 100,
    },
    {
      title: 'From',
      dataIndex: 'startDate',
      width: 100,
    },
    {
      title: 'To',
      dataIndex: 'endDate',
      width: 100,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: 100,
    },
    {
      title: 'Service',
      dataIndex: 'service',
      width: 100,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 100,
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      width: 150,
      render: text => (
        <div
          style={{
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => (
        <div className='tableactionbtn-grp'>
          <button onClick={() => handleEdit(record)}>
            <img alt='' src={getImage('images/tedit.svg')} />
          </button>
          <button onClick={() => showDeleteConfirmation(record)}>
            <img alt='' src={getImage('images/tdelete.svg')} />
          </button>
        </div>
      ),
    },
  ]

  const onScheduleTaskClick = useCallback(() => {
    setEditingTask(null)
    setIsModalVisible(true)
    form.resetFields()
  }, [form])

  const handleSearchStaff = e => {
    setSearchStaff(e.target.value)
  }

  const handleDepartmentChange = value => {
    setSelectedDepartment(value)
    setSelectedService(null)
    fetchServices(value)
  }

  const handleServiceChange = value => {
    setSelectedService(value)
  }

  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const handleModalSubmit = () => {
    setIsModalVisible(false)
    fetchTasks()
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    setEditingTask(null)
  }

  const handleEdit = async record => {
    try {
      const db = firebase.firestore()
      const hotelTasksRef = db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')

      const taskDoc = await hotelTasksRef.doc(record.id).get()
      if (!taskDoc.exists) {
        message.error('Task not found')
        return
      }

      const taskToEdit = taskDoc.data()

      if (taskToEdit.status !== 'Pending') {
        message.error('Cannot edit a task that is not pending')
        return
      }

      // Proceed with setting the form values
      setEditingTask(record)
      setIsModalVisible(true)
      form.setFieldsValue({
        id: record.id,
        startDate: moment(record.startDate, 'DD-MM-YYYY'),
        endDate: moment(record.endDate, 'DD-MM-YYYY'),
        department: record.department,
        staff: record.staffId,
        roomNumbers: record.location.split(', '),
        service: record.service,
        note: record.note,
      })
    } catch (error) {
      console.error('Error fetching task:', error)
      message.error('Failed to fetch task')
    }
  }

  const showDeleteConfirmation = record => {
    setDeleteTaskId(record.id)
    setIsDeleteModalVisible(true)
  }

  //move this to firebase query file
  const handleDelete = async () => {
    setDeleteLoading(true)
    try {
      const db = firebase.firestore()
      const hotelTasksRef = db
        .collection('tasks')
        .doc(hotelInfo.hotelId)
        .collection('hotelTasks')

      // Fetch the task to delete from Firebase
      const taskDoc = await hotelTasksRef.doc(deleteTaskId).get()
      if (!taskDoc.exists) {
        message.error('Task not found')
        setIsDeleteModalVisible(false)
        setDeleteLoading(false)
        return
      }
      const taskToDelete = tasks.find(task => task.id === deleteTaskId)
      const taskToBeDeleted = taskDoc.data()

      if (taskToBeDeleted.status !== 'Pending') {
        message.error('Cannot delete a task that is not pending')
        setIsDeleteModalVisible(false)
        setDeleteLoading(false)
        return
      }

      // Delete all tasks with the same identifiers
      const batch = db.batch()
      const snapshot = await hotelTasksRef
        .where('staffName', '==', taskToDelete.assignedStaff)
        .where('startDate', '==', taskToDelete.startDate)
        .where('endDate', '==', taskToDelete.endDate)
        .where('department', '==', taskToDelete.department)
        .where('service', '==', taskToDelete.service)
        .get()

      snapshot.docs.forEach(doc => {
        batch.delete(doc.ref)
      })

      await batch.commit()
      fetchTasks()
      setIsDeleteModalVisible(false)
      setSuccessMessage('Task deleted successfully')
      setShowSuccessModal(true)
    } catch (error) {
      console.error('Error deleting task:', error)
      message.error('Failed to delete task')
    } finally {
      setDeleteLoading(false)
    }
  }

  const filteredData = tasks.filter(
    item =>
      (item.assignedStaff?.toLowerCase() || '').includes(
        searchStaff.toLowerCase()
      ) &&
      (selectedDepartment === null || item.department === selectedDepartment) &&
      (selectedService === null || item.service === selectedService) &&
      (startDate === null ||
        moment(item.startDate, 'DD-MM-YYYY').isSame(startDate, 'day')) &&
      (endDate === null ||
        moment(item.endDate, 'DD-MM-YYYY').isSame(endDate, 'day'))
  )

  const resetFilters = () => {
    setSearchStaff('')
    setSelectedDepartment(null)
    setSelectedService(null)
    setStartDate(null)
    setEndDate(null)
    fetchServices(null)
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNameCard
                title='Recurring Task Scheduler'
                breadcrumb={['Hotel Admin', 'Recurring Task Scheduler']}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-4 col-md-3'>
                    <div className='searchbox'>
                      <Search
                        placeholder={'Search by Staff'}
                        value={searchStaff}
                        onChange={handleSearchStaff}
                      />
                    </div>
                  </div>
                  <div className='col-4 col-md-auto'>
                    <div className='cmnSelect-form' id='drpDept'>
                      <Select
                        showSearch
                        placeholder='Department'
                        optionFilterProp='children'
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                      >
                        {departments.map(dept => (
                          <Option key={dept.key} value={dept.name}>
                            {dept.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md-auto'>
                    <div className='cmnSelect-form'>
                      <Select
                        showSearch
                        placeholder='Service'
                        optionFilterProp='children'
                        value={selectedService}
                        onChange={handleServiceChange}
                      >
                        {services.map(service => (
                          <Option key={service} value={service}>
                            {service}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className='col-4 col-md-2'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        placeholder={'Start Date'}
                        onChange={handleStartDateChange}
                        format='DD-MM-YYYY'
                      />
                    </div>
                  </div>
                  <div className='col-4 col-md-2'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        placeholder={'End Date'}
                        onChange={handleEndDateChange}
                        format='DD-MM-YYYY'
                      />
                    </div>
                  </div>
                  <div className='col-6 col-md-auto '>
                    <Button
                      type='primary'
                      title='Reset Filter'
                      className='adduserbtn'
                      onClick={resetFilters}
                    >
                      <img src={getImage('images/clearicon.svg')} alt='' />
                    </Button>
                  </div>
                  <div className='col-4 col-md-auto ml-auto'>
                    <Button onClick={onScheduleTaskClick} className='cmnBtn'>
                      Schedule Recurring Task
                    </Button>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      ref={tableRef}
                      columns={taskColumns}
                      dataSource={filteredData}
                      pagination={{
                        ...PaginationOptions,
                        current: currentPage,
                      }}
                      scroll={{ y: 382 }}
                      rowKey='id'
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScheduleTaskModal
        isVisible={isModalVisible}
        onCancel={handleModalCancel}
        onSubmit={handleModalSubmit}
        form={form}
        editingTask={editingTask}
      />

      <Modal
        visible={isDeleteModalVisible}
        className='deleteModal cmnModal'
        footer={null}
        centered
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <DeleteModal
          title='Confirm Delete'
          message='Do you really want to delete this task?'
        />
        <div className='modalFooter'>
          <Button
            className='grayBtn'
            key='back'
            onClick={() => setIsDeleteModalVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className='blueBtn ml-3 ml-lg-4'
            key='submit'
            onClick={handleDelete}
            loading={deleteLoading}
          >
            Delete
          </Button>
        </div>
      </Modal>
      <Modal
        visible={showSuccessModal}
        onCancel={() => setShowSuccessModal(false)}
        className='successModal'
        footer={null}
        centered
      >
        <SuccessModal title={successMessage}></SuccessModal>
      </Modal>
      <style jsx>{`
        @keyframes blink {
          0%,
          100% {
            background-color: #adadad;
          }
        }
        .highlight-blink {
          animation: blink 0.5s step-start 10;
        }
      `}</style>
    </>
  )
}

export default RecurringTaskScheduler
