import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import Header from '../../Common/Header/Header'
import PageNamecard from '../../Common/PageNameCard/PageNameCard'
import { Tabs, Spin } from 'antd' // Import Spin from Ant Design
import {
  whiteBoxStyle,
  statusColors,
  roomContainerStyle,
  roomWrapperStyle,
} from '../../../config/constants'
import { getColor } from '../../../config/utils'
import 'antd/dist/antd.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHotelLocations } from '../../../services/location'
import { getCardStyle } from '../../../config/utils'

const { TabPane } = Tabs

const RoomComponent = ({ room, disabled }) => {
  const color = disabled ? '#808080' : getColor(room.status)
  const style = useMemo(
    () => ({
      ...getCardStyle(color),
      borderRadius: '6px',
      padding: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginRight: '10px',
      minWidth: '90px',
      height: '75px',
      width: '100%',
      maxHeight: '75px',
      maxWidth: '120px',
      backgroundColor: '#FFFFFF',
      borderWidth: '0 0 0 4px',
      borderLeft: `4px solid ${color}`,
      opacity: disabled ? 0.6 : 1,
      cursor: disabled ? 'not-allowed' : 'pointer',
    }),
    [color, disabled]
  )

  const content = (
    <div style={style}>
      <div>
        <div style={{ fontWeight: 'bold' }}>{room.location}</div>
        <div style={{ color }}>{disabled ? 'Not Assigned' : room.status}</div>
      </div>
    </div>
  )

  return disabled ? (
    content
  ) : (
    <Link
      to={`/HouseKeepingTimeScheduler?id=${room.id}`}
      style={{ textDecoration: 'none' }}
    >
      {content}
    </Link>
  )
}

const RoomView = () => {
  const [rooms, setRooms] = useState({ numbered: [], common: [] })
  const [locations, setLocations] = useState([])
  const [loadingRooms, setLoadingRooms] = useState(true) // State for loading rooms
  const [loadingLocations, setLoadingLocations] = useState(true) // State for loading locations
  const { hotelInfo, locationIdToInfo } = useSelector(state => state)
  const dispatch = useDispatch()

  const parseDate = dateString => {
    const [day, month, year] = dateString.split('-')
    return new Date(`${year}-${month}-${day}`)
  }

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const db = firebase.firestore()
        const snapshot = await db
          .collection('tasks')
          .doc(hotelInfo.hotelId)
          .collection('hotelTasks')
          .get()

        const fetchedTasks = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          startDate: doc.data().startDate
            ? parseDate(doc.data().startDate)
            : '',
          endDate: doc.data().endDate ? parseDate(doc.data().endDate) : '',
        }))

        const currentDate = new Date()
        const today = currentDate
          .toLocaleDateString('en-GB')
          .split('/')
          .join('-')

        const filteredTasks = fetchedTasks.filter(
          task => task.scheduleDate === today
        )

        const flattenedTasks = filteredTasks.flatMap(task =>
          task.roomNumbers.map(roomNumber => ({
            ...task,
            location: roomNumber,
            uniqueId: `${task.id}-${roomNumber}`,
          }))
        )

        const numbered = flattenedTasks.filter(room =>
          /^\d+$/.test(room.location)
        )
        const common = flattenedTasks.filter(
          room => !/^\d+$/.test(room.location)
        )

        setRooms({ numbered, common })
      } catch (error) {
        console.error('Error fetching tasks:', error)
      } finally {
        setLoadingRooms(false) // Set loading to false after fetching rooms
      }
    }

    fetchTasks()
  }, [hotelInfo.hotelId])

  useEffect(() => {
    if (hotelInfo.hotelId) {
      fetchHotelLocations({ dispatch, hotelId: hotelInfo.hotelId })
      setLoadingLocations(false)
    }
  }, [dispatch, hotelInfo.hotelId])

  useEffect(() => {
    if (locationIdToInfo) {
      setLocations(
        Object.values(locationIdToInfo).map(location => ({
          ...location,
          isNumeric: /^\d+$/.test(location.locationName),
        }))
      )
    }
  }, [locationIdToInfo])

  const ColorLegend = () => (
    <div className='color-legend-list'>
      <ul>
        {Object.keys(statusColors).map(status => (
          <li key={status}>
            <div style={{ backgroundColor: statusColors[status] }} />
            <span>{status}</span>
          </li>
        ))}
      </ul>
    </div>
  )

  const getRoomsToDisplay = (roomsData, locationsData, isNumeric) => {
    const assignedRooms = new Set(roomsData.map(room => room.location))
    const allRooms = locationsData
      .filter(location => location.isNumeric === isNumeric)
      .map(location => ({
        location: location.locationName,
        status: assignedRooms.has(location.locationName)
          ? 'Assigned'
          : 'Not Assigned',
        id: location.id,
      }))

    const sortedRooms = allRooms.sort((a, b) => {
      if (a.status === 'Not Assigned' && b.status !== 'Not Assigned') return 1
      if (a.status !== 'Not Assigned' && b.status === 'Not Assigned') return -1
      return 0
    })

    return sortedRooms.map(room => {
      const assignedRoom = roomsData.find(r => r.location === room.location)
      return assignedRoom || { ...room, disabled: true }
    })
  }

  const numberedRooms = getRoomsToDisplay(rooms.numbered, locations, true)
  const commonRooms = getRoomsToDisplay(rooms.common, locations, false)

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='iNPLASS Room View'
                breadcrumb={['Admin', 'RoomView']}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            <ColorLegend />
          </div>
          <div style={whiteBoxStyle}>
            {loadingRooms || loadingLocations ? ( // Show loading spinner when data is being fetched
              <div
                style={{ textAlign: 'center', margin: '20px 0', width: '100%' }}
              >
                <Spin size='default' />
              </div>
            ) : (
              <Tabs defaultActiveKey='1' style={{ marginLeft: 15 }}>
                <TabPane tab='Rooms' key='1'>
                  <div style={roomContainerStyle}>
                    {numberedRooms
                      .sort((a, b) => {
                        const roomA = parseInt(a.location, 10)
                        const roomB = parseInt(b.location, 10)
                        return roomA - roomB
                      })
                      .map(room => (
                        <div
                          key={room.uniqueId || room.id}
                          style={roomWrapperStyle}
                        >
                          <RoomComponent room={room} disabled={room.disabled} />
                        </div>
                      ))}
                  </div>
                </TabPane>
                <TabPane tab='Common Area' key='2'>
                  <div style={roomContainerStyle}>
                    {commonRooms.map(room => (
                      <div
                        key={room.uniqueId || room.id}
                        style={roomWrapperStyle}
                      >
                        <RoomComponent room={room} disabled={room.disabled} />
                      </div>
                    ))}
                  </div>
                </TabPane>
              </Tabs>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default RoomView
